import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useNFLPostseasonSchedule = () => {
    return useMutation({
        mutationFn: nflPostseasonScheduleRequest,
        onError: (error) => {
        console.error('Error getting NFL Postseason Schedule:', error);
        },
        // onSuccess: (data) => {
        // console.log('Get NFL Postseason Schedule:', data);
        // }
    });
}

const nflPostseasonScheduleRequest = async (season) => {
    //console.log("Season", season);
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-nfl-postseason-schedule`, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        },
        params: { season } // Pass season as a query parameter
    });
    return response.data;
};