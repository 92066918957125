import React from 'react';
import PageComponentsContainer from '../../PageComponents/PageComonentsContainer';

const SponsorMessage = ({ sponsorMessageInfo }) => {

    return (
        <PageComponentsContainer content={sponsorMessageInfo} />
    );
}

export default SponsorMessage;