import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';

const Buttons = ({ title, buttons }) => {
  const handleClick = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 'md', margin: '0 auto' }}>
      {title && (
        <Typography
          variant="h6"
          sx={{
            fontSize: title.fontSize || '16px',
            color: title.color || '#002129',
            fontWeight: title.weight || '700',
            textDecoration: title.underline ? 'underline' : 'none',
            mb: 2
          }}
        >
          {title.text}
        </Typography>
      )}
      
      <Stack spacing={2} width="100%" alignItems="center">
        {buttons?.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => handleClick(button.link)}
            sx={{
              width: '275px',
              backgroundColor: button.color || '#00AAD1',
              color: '#FFFFFF',
              p: 1.5,
              '&:hover': {
                backgroundColor: button.color || '#00AAD1',
                opacity: 0.9
              }
            }}
          >
            {button.text}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default Buttons;