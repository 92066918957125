import React from 'react';
import { Link, Typography, Box, Card, CardContent } from '@mui/material';
import Images from './PageComponentsFolder/Images';
import Links from './PageComponentsFolder/Links';
import Video from './PageComponentsFolder/Video';
import Locations from './PageComponentsFolder/Locations';
import Text from './PageComponentsFolder/Text';
import Buttons from './PageComponentsFolder/Buttons';
import Lists from './PageComponentsFolder/Lists';


const PageComponentsContainer = ({ content }) => {
  const containerStyles = !content[0]?.type ? content[0] : {};
  const components = !content[0]?.type ? content.slice(1) : content;
  
  const componentMap = {
      text: Text,
      image: Images,
      links: Links,
      video: Video,
      locations: Locations,
      buttons: Buttons,
      list: Lists,
  };

  return (
      <Box 
          sx={{ 
              padding: '16px',
              backgroundColor: containerStyles?.backgroundColor,
              ...containerStyles
          }}
      >
          {components?.map((item, index) => {
              const Component = componentMap[item.type];
              return Component ? (
                  <Card 
                      key={index} 
                      sx={{ 
                          backgroundColor: containerStyles?.backgroundColor || 'transparent',
                          boxShadow: 'none',
                          borderRadius: 0,
                          '& .MuiCardContent-root': {
                              padding: '0 !important',
                              paddingBottom: index === components.length - 1 ? '0 !important' : '16px !important'
                          }
                      }}
                  >
                      <CardContent>
                          <Component {...item} />
                      </CardContent>
                  </Card>
              ) : null;
          })}
      </Box>
  );
};

export default PageComponentsContainer;