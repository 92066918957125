import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Images = ({ images, title, smallButtons }) => {
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const handleClick = (link, e) => {
    e.preventDefault();
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box>
      {title && (
        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#002129', mb: 2 }}>
          {title}
        </Typography>
      )}
      <Grid container spacing={smallButtons ? 1 : 2} justifyContent="center" alignItems="center" gap={smallButtons ? 1 : 3}>
        {images?.map((image, index) => (
          <Grid 
            item 
            xs={12} 
            md={images.length === 1 ? 12 : 6} 
            key={index}
            sx={{
              maxWidth: {
                xs: '400px',
                md: smallButtons ? '175px' : (images.length === 1 ? '500px' : '400px')
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              {/* Header Text */}
              {image?.headerText?.map((header, idx) => (
                <Box 
                  key={`header-${idx}`}
                  onMouseEnter={() => header.link && setHoveredGroup(`header-${index}-${idx}`)}
                  onMouseLeave={() => setHoveredGroup(null)}
                >
                  {Array.isArray(header.text) 
                    ? header.text.map((line, lineIndex) => (
                        <Typography
                          key={`header-line-${lineIndex}`}
                          onClick={header.link ? (e) => handleClick(header.link, e) : undefined}
                          sx={{
                            fontSize: header.size || '16px',
                            color: header.color || '#002129',
                            fontWeight: header.fontWeight || '500',
                            mb: 1,
                            cursor: header.link ? 'pointer' : 'default',
                            textDecoration: hoveredGroup === `header-${index}-${idx}` ? 'underline' : 'none'
                          }}
                        >
                          {line}
                        </Typography>
                      ))
                    : (
                        <Typography
                          onClick={header.link ? (e) => handleClick(header.link, e) : undefined}
                          sx={{
                            fontSize: header.size || '16px',
                            color: header.link ? '#00AAD1' : header.color ? header.color : '#002129',
                            fontWeight: header.fontWeight || '500',
                            mb: 1,
                            cursor: header.link ? 'pointer' : 'default',
                            textDecoration: hoveredGroup === `header-${index}-${idx}` ? 'underline' : 'none'
                          }}
                        >
                          {header.text}
                        </Typography>
                      )}
                </Box>
              ))}

              {/* Image */}
              <Box
                component="img"
                src={image?.src}
                alt={image?.alt}
                onClick={image.link ? (e) => handleClick(image.link, e) : undefined}
                sx={{ 
                  width: image?.width || '100%',
                  height: 'auto', 
                  borderRadius: 1,
                  cursor: image.link ? 'pointer' : 'default'
                }}
              />

              {/* Footer Text */}
              {image?.footerText?.map((footer, idx) => (
                <Box 
                  key={`footer-${idx}`}
                  onMouseEnter={() => footer.link && setHoveredGroup(`footer-${index}-${idx}`)}
                  onMouseLeave={() => setHoveredGroup(null)}
                >
                  {Array.isArray(footer.text) 
                    ? footer.text.map((line, lineIndex) => (
                        <Typography
                          key={`footer-line-${lineIndex}`}
                          onClick={footer.link ? (e) => handleClick(footer.link, e) : undefined}
                          sx={{
                            fontSize: footer.size,
                            color: footer.color,
                            fontWeight: footer.fontWeight || '500',
                            mt: 1,
                            cursor: footer.link ? 'pointer' : 'default',
                            textDecoration: hoveredGroup === `footer-${index}-${idx}` ? 'underline' : 'none'
                          }}
                        >
                          {line}
                        </Typography>
                      ))
                    : (
                        <Typography
                          onClick={footer.link ? (e) => handleClick(footer.link, e) : undefined}
                          sx={{
                            fontSize: footer.size,
                            color: footer.color,
                            fontWeight: footer.fontWeight || '500',
                            mt: 1,
                            cursor: footer.link ? 'pointer' : 'default',
                            textDecoration: hoveredGroup === `footer-${index}-${idx}` ? 'underline' : 'none'
                          }}
                        >
                          {footer.text}
                        </Typography>
                      )}
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Images;