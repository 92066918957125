import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../../BirdiePool/hooks/index";
import { useGetGameMembers } from "../../../../hooks/general/useGetGameMembers";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../../redux/actions/loginImport";
import useNavigateToTop from "../../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../../redux/actions/loginImport";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import { useCFBTeams, useCFBGamesByWeek, useCFBNames, useCFBCurrentWeek, useQuickPickCFB, useCFBPostseasonSchedule } from "../../../../hooks/cfb/index";
import { useGetPoolLeaderboard } from "../../../../hooks/pools/useGetPoolLeaderboard";
import { DateTime } from "luxon";
import { useGetGameReferrals } from "../../../../hooks/pools/useGetGameReferrals";

const QuickPickCFBContext = createContext();

export const useQuickPickCFBContext = () => {
    const context = useContext(QuickPickCFBContext);
    if (!context) {
        throw new Error("useQuickPickCFBContext must be used within a QuickPickCFBProvider");
    }
    return context;
};

const QuickPickCFBProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log("myUserId: ", myUserId);

    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchCFBTeams, data: cfbTeams, isLoading: cfbTeamsLoading, isError: cfbTeamsError, error: cfbTeamsErrorData } = useCFBTeams();
    const { mutate: fetchCFBGamesByWeek, data: cfbGamesByWeekData, isLoading: cfbGamesByWeekLoading, isError: cfbGamesByWeekError, error: cfbGamesByWeekErrorData } = useCFBGamesByWeek();
    const { mutate: fetchCFBNames, data: cfbNames, isLoading: cfbNamesLoading, isError: cfbNamesError, error: cfbNamesErrorData } = useCFBNames();
    const { mutate: fetchCFBCurrentWeek, data: cfbCurrentWeek, isLoading: cfbCurrentWeekLoading, isError: cfbCurrentWeekError, error: cfbCurrentWeekErrorData } = useCFBCurrentWeek();
    const { mutate: fetchQuickPickCFB, data: quickPickCFBData, isLoading: quickPickCFBLoading, isError: quickPickCFBError, error: quickPickCFBErrorData } = useQuickPickCFB();
    const { mutate: fetchCFBPostseasonSchedule, data: cfbPostseasonScheduleData, isLoading: cfbPostseasonScheduleLoading, isError: cfbPostseasonScheduleError, error: cfbPostseasonScheduleErrorData } = useCFBPostseasonSchedule();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: createSubgroup, data: subgroupData, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();
    const { mutate: fetchGameMembers, data: gameMembersData, isLoading: gameMembersLoading, isError: gameMembersError, error: gameMembersErrorData } = useGetGameMembers();
    const { mutate: fetchGameReferrals, data: gameReferrals } = useGetGameReferrals();

    const { gameId, component, pageState } = useParams();

    const gameType = "QuickPick";
    const league = "CFB";

    const dispatch = useDispatch();
    const navigateToTop = useNavigateToTop();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    //console.log("userData: ", userData);

    // Fetch game data second
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    //console.log("gameData: ", gameData);

    const season = gameData?.season;
    const includePostseason = gameData?.gameParameters[0]?.includePostseason;
    const includeRegularSeason = gameData?.gameParameters[0]?.includeRegularSeason;
    const dontUseReferrals = gameData?.gameParameters[0]?.dontUseReferrals;
    //console.log("Season: ", season, "Include Postseason: ", includePostseason, "Include Regular Season: ", includeRegularSeason);

    // Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
            if (!dontUseReferrals) {
                console.log("Getting Referrals");
                fetchGameReferrals({ gameID: gameId });
            } else {
                console.log("Not Getting Referrals");
            }
        }
    }, [gameData, gameId, fetchLeaderboardData, fetchGameReferrals]);
    //console.log("leaderboardData CONTEXT: ", leaderboardData);

    //Error handling for Leaderboard Data
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }
    }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

   

    
    const [pickState, setPickState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(null);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState("");
    const [partyId, setPartyId] = useState(gameId);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    //console.log("Data Fetched????? ", hasFetchedData);
    //console.log("Default Party ID: ", defaultPartyId, "Default Party Name: ", defaultPartyName);
    //console.log("PartyID Context: ", partyId, "PartyState: ", partyState);


    useEffect(() => {
        //console.log("Running Use Effect");
        if (!hasFetchedData && season) {
                //console.log("Fetching CFB Data");
                fetchCFBTeams();
                fetchCFBNames();
                fetchQuickPickCFB(season);
                if (includePostseason) {
                    fetchCFBPostseasonSchedule(season);
                }
                if (includeRegularSeason) {
                    fetchCFBGamesByWeek();
                    fetchCFBCurrentWeek();
                }

            setHasFetchedData(true);
        }
    }, [hasFetchedData, season, fetchCFBTeams, fetchCFBGamesByWeek, fetchCFBNames, fetchCFBCurrentWeek, fetchQuickPickCFB, fetchCFBPostseasonSchedule, includePostseason, includeRegularSeason]);
    //console.log("cfbTeams: ", cfbTeams, "cfbGamesByWeek: ", cfbGamesByWeekData, "cfbNames: ", cfbNames, "cfbCurrentWeek: ", cfbCurrentWeek, "quickPickCFBData: ", quickPickCFBData, "cfbPostseasonScheduleData: ", cfbPostseasonScheduleData);

    const gameParameters = gameData?.gameParameters[0];
    //console.log("gameParameters: ", gameParameters);
    const isTiebreakerGame = gameParameters?.tiebreakerGame;
    const tiebreakerGamesInfo = gameParameters?.tiebreakerGamesInfo;
    //console.log("Is Tiebreaker Game: ", isTiebreakerGame, "Tiebreaker Games Info: ", tiebreakerGamesInfo);

    let myTiebreakerGames = [];
    if (isTiebreakerGame && tiebreakerGamesInfo?.length > 0) {
        // Filter and find the matching entries from tiebreakerGamesInfo
        const matchingTiebreakerGames = tiebreakerGamesInfo?.filter(info =>
            userData?.Games?.some(game => game.gameID === info.id) // Compare info.id with game.gameID
        );

        // Push the matching entries into myTiebreakerGames
        if (matchingTiebreakerGames?.length > 0) {
            myTiebreakerGames?.push(...matchingTiebreakerGames); // Use spread operator to add all matching entries
        }
    }

    //console.log("My Tiebreaker Games: ", myTiebreakerGames);

    // Use Effect to get Game Members if myTimebreakerGames is not empty
    const stableFetchGameMembers = useCallback(fetchGameMembers, []); // Stable reference

    useEffect(() => {
        //console.log("running Use Effect for Game Members");
        if (myTiebreakerGames?.length > 0) {
            const gameIDs = myTiebreakerGames?.map(game => game.id);
            stableFetchGameMembers({ gameIDs });
        }
    }, [JSON.stringify(myTiebreakerGames), stableFetchGameMembers]); // Dependencies are stable and compared properly

    //console.log("Game Members Data: ", gameMembersData);



        
    const showMembersPage = gameData?.showMembersPage;
    const picksPerInterval = gameParameters?.picksPerInterval;
    const usingTiebreaker = gameParameters?.usingTiebreaker;
    const tiebreakerInfo = gameParameters?.tiebreakerInfo;
    const intervalType = gameParameters?.intervalType;
    const props = gameParameters?.props;
    const propIdsToUse = gameParameters?.propIdsToUse;
    const howManyWinners = gameParameters?.howManyWinners;
    const gamePropsToUse = props?.gameProps;
    const selectedTeamPropsToUse = props?.selectedTeamProps;
    const opponentTeamPropsTouse = props?.opponentTeamProps;
    const firstInterval = gameParameters?.firstInterval;
    const lastInterval = gameParameters?.lastInterval;
    const teamsToInclude = gameParameters?.teamsToInclude;
    const gamesToInclude = gameParameters?.gamesToInclude;
    const countdownMessageFromParams = gameParameters?.countdownMessage;
    const countdownText = gameParameters?.countdownText;
    const countdownBackground = gameParameters?.countdownBackground;
    const useColorsForHeader = gameParameters?.useColorsForHeader;
    const daysToInclude = gameParameters?.daysToInclude;  // Leave this as undefined or null for no filtering, format is ["Sunday", "Saturday"]
    const filterTime = gameParameters?.filterTime;  // Leave this as undefined or null for no filtering, format is "HH:MM" (18:00 for 6:00 PM)
    const filterDate = gameParameters?.filterDate; // Leave this as undefined or null for no filtering, format is "YYYY-MM-DD" (2024-12-25 for December 25, 2024)
    const homeOnly = gameParameters?.homeOnly;  // If true, only include games where the home team is in teamsToInclude
    const awayOnly = gameParameters?.awayOnly;  // If true, only include games where the away team is in teamsToInclude
    const showConsenus = gameParameters?.showConsenus; // If true, show the consensus pick for each game
    const customBackgroundColor = gameParameters?.customBackgroundColor || null;
    const customTextColor = gameParameters?.customTextColor || null;
    const customBorderColor = gameParameters?.customBorderColor || null;
    const myPicksMobileImage = gameParameters?.myPicksMobileImage;
    const myPicksDesktopImage = gameParameters?.myPicksDesktopImage;
    const isGameOver = gameParameters?.isGameOver;

    const joinModalText = gameData?.joinModalText;

    const currentInterval = includeRegularSeason ? cfbCurrentWeek : 1;

    let byeWeek = null;
    // Will need more work here when regular season is included!!!!!!!!

    useEffect(() => {
        //console.log("Running Use Effect");
        if (userData && gameId) {
            const thisGame = userData?.Games?.find(game => game.GameID === gameId);
            if (thisGame && thisGame?.pool?.picks) {
                setPickState(thisGame.pool.picks);
            }
        }
    }, [userData, gameId]);

    const userSubgroups = userData?.subgroups || [];

    let gameSchedule = includeRegularSeason ? cfbGamesByWeekData : cfbPostseasonScheduleData;

    //console.log("Games To Include: ", gamesToInclude);
   // FILTER GAMES BY PARAMS, will need updated when custom games are included
    if (firstInterval && gameSchedule && gameParameters) {
        if (includeRegularSeason) {
            gameSchedule = gameSchedule?.filter((game) => {
                // Check homeOnly and awayOnly conditions
                const isHomeTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalHomeTeamID); // Include all if teamsToInclude is undefined
                const isAwayTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalAwayTeamID); // Include all if teamsToInclude is undefined
                // Include if GlobalGameID is in gamesToInclude
                //console.log("Game ID: ", game.GlobalGameID);
                const isGameIdIncluded = !gamesToInclude || gamesToInclude.includes(game.GlobalGameID); // Include all if gamesToInclude is undefined
                
                return isHomeTeamIncluded || isAwayTeamIncluded || isGameIdIncluded; // Return condition for filter
            });
        } else if (includePostseason) {
            gameSchedule = gameSchedule?.filter((game) => {
                const isGameIdIncluded = !gamesToInclude || gamesToInclude.includes(game.GlobalGameID); // Include all if gamesToInclude is undefined
                return isGameIdIncluded; // Return condition for filter
            });
        }
    }

    //console.log("Game Schedule AFTER Filter: ", gameSchedule);

    const nearestGame = gameSchedule
    ?.sort((a, b) => new Date(a.Day) - new Date(b.Day))
    ?.find((game) => {
        const nowInNewYork = DateTime.now().setZone('America/New_York');
        const gameDate = DateTime.fromISO(game.Day, { zone: 'America/New_York' });
        return gameDate >= nowInNewYork;
    });

    const nearestGameWeek = nearestGame?.Week;

    const oneWeekEvent = firstInterval === lastInterval;

    let countdownTimer = true;
    if (!nearestGame) {
        countdownTimer = false;
    }

    let countdownMessage = `Week ${nearestGameWeek} picks lock in:`;
    if (oneWeekEvent && countdownMessageFromParams) {
        countdownMessage = countdownMessageFromParams;
    }

    const countdownDeadline = nearestGame?.DateTime;

    let quickPickCFBGames = quickPickCFBData;

    const currentPropIds = propIdsToUse?.filter((entry) => entry.interval === interval)[0]?.propIdsToUse || []; 

    //Fiter quickPickCFBGames to include only games that are also in gameSchedule
    if (quickPickCFBGames && gameSchedule) {
        quickPickCFBGames = quickPickCFBGames?.filter((game) => {
            // First find matching game based on GlobalGameID
            const matchingGame = gameSchedule?.find((g) => g.GlobalGameID === game.GlobalGameID);
            return matchingGame; // Return condition for filter
        });
    }
    //console.log("quickPickCFBGames: AFTER filter ", quickPickCFBGames);

    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime, { zone: 'America/New_York' });
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];
    const referralPrizes = gameData?.referralPrizes;

    //Find the game in user data
    let doesUserBelong = false;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    }
    //console.log("Does User Belong: ", doesUserBelong, "ThisGame: ", thisGame, "Game Id: ", gameId, "User Data: ", userData);

    let textOptIn = gameData?.textOptIn;

    const amIOptedInToTexts = textOptIn?.includes(myUserId);

    const myUsername= userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards.find(reward => reward.interval === 0);

   

    // Utility function to check if defaultPartyIdInDB is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        return subgroups.some(subgroup => subgroup.subGroupID === partyId);
    };

    // Get the default party ID with the additional check
    let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
    ? thisGame.pool.defaultParty[0]
    : gameId;

    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB);


    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = gameName ? gameName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : gameName ? gameName : "Pool Party";
    }

    //console.log("Default Party Name in DB", defaultPartyNameInDB);

    // Use Effect to set default party ID and name
    useEffect(() => {
        //console.log("Setting Default Party ID and Name");
        setDefaultPartyId(defaultPartyIdInDB);
        setDefaultPartyName(defaultPartyNameInDB);
    }, [defaultPartyIdInDB, defaultPartyNameInDB]); 


     //Use Effect for setting the week and party state based on the component and page state
     useEffect(() => {
        //console.log("Running Use Effect for Setting Week and Party State");
        if (gameData && component) {
            let newInterval = currentInterval;

            if (component === "MY_PICKS") {
                if (pageState !== "CURRENT") {
                    const intervalNumber = parseInt(pageState, 10);

                    if (isNaN(intervalNumber) || intervalNumber < firstInterval) {
                        newInterval = firstInterval;
                    } else if (intervalNumber > lastInterval) {
                        newInterval = lastInterval;
                    } else {
                        newInterval = intervalNumber;
                    }
                } else if (firstInterval > currentInterval) {
                    newInterval = firstInterval;
                } else if (lastInterval < currentInterval) {
                    newInterval = lastInterval;
                } else {
                    newInterval = currentInterval;
                }
            }

            if (newInterval !== interval) {
                setInterval(newInterval);
            }

            if (component === "LEADERBOARD" || component === "POOL_PICKS") {
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups?.find(
                        (subgroup) => subgroup.subGroupID === pageState
                    );
                    const pageStateInMyTiebreakerGames = myTiebreakerGames?.find(
                        (game) => game.id === pageState
                    );
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                    } else if (pageStateInMyTiebreakerGames) {
                        setPartyId(pageState);
                        setPartyState(pageStateInMyTiebreakerGames.name);
                    } else {
                        setPartyId(gameId);
                        setPartyState(gameName ? gameName : "Pool Party");
                        navigate(
                            `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`
                        );
                    }
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(
                        `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded("MY_PARTY")}/${uriEncoded(
                            lastSubgroupId
                        )}`
                    );
                } else {
                    const subgroupName = userSubgroups?.find(
                        (subgroup) => subgroup.subGroupID === pageState
                    )?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [
        userData,
        gameData,
        component,
        pageState,
        currentInterval,
        firstInterval,
        lastInterval,
        interval,
        gameId,
        userSubgroups,
        defaultPartyId,
        defaultPartyName,
        lastSubgroupId,
        lastSubgroupName,
        gameAddress,
        navigate,
    ]);


    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;

    const gatherGameInfo = (gameId, teams, schedule, standings, names) => {
        const gameInfo = schedule?.find(game => game.GlobalGameID === gameId);
        const homeTeamId = gameInfo?.GlobalHomeTeamID;
        const awayTeamId = gameInfo?.GlobalAwayTeamID;
        const homeLogo = teams?.find(team => team.GlobalTeamID === homeTeamId)?.TeamLogoUrl;
        const awayLogo = teams?.find(team => team.GlobalTeamID === awayTeamId)?.TeamLogoUrl;
        const homeName = names?.find(team => team.GlobalTeamID === homeTeamId)?.ShortDisplayName;
        const awayName = names?.find(team => team.GlobalTeamID === awayTeamId)?.ShortDisplayName;
        const homeWins = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Wins || 0;
        const homeLosses = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Losses || 0;
        const homeTies = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Ties || 0;
        const homeRecord = homeTies > 0 ? `${homeWins}-${homeLosses}-${homeTies}` : `${homeWins}-${homeLosses}`;
        const awayWins = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Wins || 0;
        const awayLosses = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Losses || 0;
        const awayTies = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Ties || 0;
        const awayRecord = awayTies > 0 ? `${awayWins}-${awayLosses}-${awayTies}` : `${awayWins}-${awayLosses}`;
        const homeScore = gameInfo?.HomeTeamScore;
        const awayScore = gameInfo?.AwayTeamScore;
        const gameTime = gameInfo?.DateTime;
        const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
        const status = gameInfo?.Status;
        const homeSpread = gameInfo?.PointSpread;
        const gameLine = homeSpread < 0 ? `(${homeName} ${homeSpread})` : homeSpread === 0 ? `Pick 'Em` : homeSpread > 0 ? `(${awayName} ${homeSpread * -1})` : "No Line";

        return {
            homeLogo,
            awayLogo,
            homeName,
            awayName,
            homeRecord,
            awayRecord,
            homeScore,
            awayScore,
            gameLine,
            gameTime,
            gameDateTime,
            status,
        };
    };


    const gatherDisplayBarInfo = (teamId, teams) => {
        const teamLogo = teams?.find(team => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
        const teamNickname = teams?.find(team => team.GlobalTeamID === teamId)?.Name;

        return {
            teamLogo,
            teamNickname
        };
    }

    useEffect(() => {
        if (gameData  && leaderboardData && cfbTeams && cfbNames && quickPickCFBData && (cfbGamesByWeekData || cfbPostseasonScheduleData)) {  
            setLoading(false);
        }
      }, [gameData, leaderboardData, cfbTeams, cfbNames, quickPickCFBData, cfbGamesByWeekData, cfbPostseasonScheduleData]);


    const QuickPickCFBContextValues = {
        pickState,
        setPickState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        joinOpen,
        setJoinOpen,
        gameAddress,
        gameData,
        myUserId,
        mainGameId: gameId,
        userSubgroups,
        doesUserBelong,
        currentInterval,
        firstInterval,
        lastInterval,
        gameType,
        isGameOver,
        hasJoinDeadlinePassed,
        gameName,
        myUsername,
        userPicksFromDB,
        cfbCurrentWeek,
        cfbTeams,
        cfbGamesByWeekData,
        cfbNames,
        cfbPostseasonScheduleData,
        quickPickCFBGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamesToInclude,
        gamePropsToUse,
        leaderboardInfo: leaderboardData,
        userData,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
        byeWeek,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        customBackgroundColor,
        customTextColor,
        customBorderColor,
        myPicksDesktopImage,
        myPicksMobileImage,
        amIOptedInToTexts,
        referralPrizes,
        countdownText,
        countdownBackground,
        gameSchedule,
        isTiebreakerGame,
        tiebreakerGamesInfo,
        myTiebreakerGames,
        gameMembersData,
        gameReferrals,
        useColorsForHeader,
        showMembersPage,
    };

    // ADD LOADING SCREEN HERE
    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <QuickPickCFBContext.Provider value={QuickPickCFBContextValues}>
            {children}
        </QuickPickCFBContext.Provider>
    );
};

export default QuickPickCFBProvider;