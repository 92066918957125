import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from '../../config';

export const usePromoteDemoteCommissioner = () => {
    return useMutation({
        mutationFn: promoteDemoteCommissionerRequest,
        onError: error => {
        console.error('Error promoting/demoting commissioner:', error);
        },
        onSuccess: data => {
        console.log('Commissioner promoted/demoted successfully:', data);
        }
    });
};

const promoteDemoteCommissionerRequest = async commissionerData => {
    console.log("Commissioner Data:", commissionerData);
    const authorizationHeader = localStorage.getItem('authorization'); // Get auth token from localStorage
    console.log(commissionerData);
    const response = await axios.post(`${apiUrl}/user/promote-demote-commissioner`, commissionerData, {
        headers: {
        Authorization: authorizationHeader, // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });
    return response.data;
};