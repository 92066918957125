import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useNFLSuperPickemContext } from './NFLSuperPickemContext';
import DynamicPoolHeaderBar from '../../PoolHeaderBar/DynamicPoolHeaderBar';
import SuperPickemMyPicks from '../MyPicks/SuperPickemMyPicks';
import { DateTime } from 'luxon';
import GameRulesPage from '../../GameRulesPage/GameRulesPage';
import PartyDropdown from '../../PartyDropdown/MyPartyDropdown';
import GameHomePageNew from '../../GameHomePage/GameHomePageNew';
import GameSponsorHeader from '../../PoolHeaderBar/GameSponsorHeader';
import JoinGameModal from '../../GameModals/JoinGameModal/JoinGameModal';
import JoinBarBattleModal from '../../GameModals/JoinGameModal/JoinBarBattleModal';
import MyParty from '../../MyParty/MyParty';
import SponsorTab from '../../SponsorTab/SponsorTab';
import SuperPickemNav from '../SuperPickemNav/SuperPickemNav';
import SuperPickemUserDash from '../UserDash/SuperPickemUserDash';
import SuperPickemLeaderboard from '../Leaderboard/SuperPickemLeaderboard';
import SuperPickemPoolPicks from '../PoolPicks/SuperPickemPoolPicks';
import QuickPickRewards from '../../QuickPickGames/Rewards/QuickPickRewards';
import RewardsRedeemHeader from '../../QuickPickGames/Rewards/RewardsRedeemHeader';
import GameLogoDisplay from '../../GameHomePage/GameHomePageComponents/GameLogoDisplay';
import ReferralsLeaderboard from '../../ReferralsLeaderboard/ReferralsLeaderboard';
import { Typography } from '@mui/material';
import KOTNMissingPicks from './KOTNMissingPicks';
import { gatherLogoNFL, gatherStadiumInfoNFL, gatherDisplayInfoNFL, gatherScoresNFL, gatherLineDisplayNFL, getBracketInfoPreLockNFL, getBracketInfoPostLockNFL, getPickStatsGameInfoNFL, getPickStatsPickInfoNFL, gatherPickEmCardTopLeftInfoNFL, gatherModalInfoNFL, gatherPoolPicksHeaderInfoNFL, processChampStatsNFL } from '../utils/nflUtils';
import "../CFB/CFBContainerStyles.scss";
import AlreadyInBattleModal from '../../GameModals/AlreadyInBattleModal/AlreadyInBattleModal';
import { useGetPoolLeaderboard } from '../../../hooks/pools/useGetPoolLeaderboard';
import EightTeamBracket from '../../Brackets/EightTeamBracket/EightTeamBracket';
import EightTeamBracketLive from '../../Brackets/EightTeamBracket/EightTeamBracketLive';
import SuperPickemWinnersDisplay from '../../ChampionDisplay/SuperPickemWinnersDisplay';
import TiebreakerGameButtons from '../../QuickPickGames/TiebreakerGameButtons/TiebreakerGameButtons';
import SuperPickemTiebreakerModal from '../../GameModals/SuperPickemModals/SuperPickemTiebreakerModal';

const NFLSuperPickemContainer = () => {
    const {
        myUserId,
        mainGameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        nflTeams,
        nflStadiums,
        nflStandings,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup,
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        firstSubgroupId,
        firstSubgroupName,
        tiebreakerInfo,
        gameLogoDisplayInfo,
        gameReferrals,
        referralPrizes,
        bracketLogo,
        pickemLogo,
        bracketTiebreakerInfo,
        amIOptedInToTexts,
        countdownBackground,
        countdownText,
        hostIds,
        hostBackgroundColor,
        maxBracketPoints,
        nflScoresBySeason,
        firstBowlTime,
        nonBracketGames,
        combinedSchedule,
        showMyPicksBar,
        isHostGame,
        affiliatedGamesInfo,
        isTeamGame,
        hostGameData,
        affiliatedGame,
        userAlreadyInOtherGame,
        setUserAlreadyInOtherGame,
        hostGameId,
        tShirtReward,
        tShirt,
        bracketPicksToMake,
        topBracketLogo,
        bottomBracketLogo,
        showMyBracketModal,
        hasFinalStarted,
        tiebreakerGameInfo,
        timeToShowTiebreaker,
        hasUserSeenTiebreakerModal,
    } = useNFLSuperPickemContext(); 

    const { mutate: getPoolLeaderboard, data: barBattleLeaderboard } = useGetPoolLeaderboard();

    //console.log("Non Bracket Games:", nonBracketGames);
    const isBracketReady = nonBracketGames?.every(game => game.finalized);
    //console.log("Are Non Bracket Games Finalized:", isBracketReady);

    useEffect(() => {
        //console.log("Checking For Bar Battle Leaderboard");
        if (isTeamGame && hostGameId) {
            getPoolLeaderboard({ gameID: hostGameId });
        }
    }, [isTeamGame, hostGameId, getPoolLeaderboard]);
    //console.log("Bar Battle Leaderboard:", barBattleLeaderboard);


    const howManyRounds = rounds?.length;

    const getUserPointsAndRanks = (userId, leaderboardData) => {
        // Find the user data once
        const user = leaderboardData?.find((user) => user.userId === userId) || {};
    
        // Helper function to check if a rank is tied
        const isTied = (rank, key) => {
            // Log inputs and current data state
            //console.log(`Checking tie for rank: ${rank}, key: ${key}`);
            
            const tiedUsers = leaderboardData?.filter((user) => user[key] === rank);
            
            // Log filtered results and their count
            //console.log(`Filtered users with rank ${rank} on ${key}:`, tiedUsers);
            //console.log(`Count of users with rank ${rank} on ${key}:`, tiedUsers?.length);
    
            return tiedUsers?.length > 1;
        };
    
        // Extract relevant information
        return {
            myRank: user.overallRank || null,
            myBracketRank: user.bracketRank || null,
            myPicksRank: user.dailyPicksRank || null,
            myTotalPoints: user.totalPoints || 0,
            myBracketPoints: user.bracketPoints || 0,
            myPicksPoints: user.dailyPicksPoints || 0,
            overallTied: isTied(user.overallRank, 'overallRank'),
            bracketTied: isTied(user.bracketRank, 'bracketRank'),
            picksTied: isTied(user.dailyPicksRank, 'dailyPicksRank'),
        };
    };
    

    const fullLeaderboard = useMemo(() => {
        return leaderboardData ? leaderboardData : [];
    }, [leaderboardData]);
  
    //console.log("Full Leaderboard:", fullLeaderboard);

    const totalEntrants = isTeamGame ? barBattleLeaderboard?.length : fullLeaderboard?.length;

    const [leaderboard, setLeaderboard] = useState(fullLeaderboard);
    //console.log("Leaderboard, From State:", leaderboard);


     //Helper function to assign ranks to users
     const assignSubgroupRanks = (leaderboardData) => {
        // Function to calculate ranks for a given sorted array
        const calculateRanks = (sortedArray, key) => {
            const ranks = Array(sortedArray.length).fill(0);
            let rank = 1;
            for (let i = 0; i < sortedArray.length; i++) {
                if (i > 0 && sortedArray[i][key] === sortedArray[i - 1][key]) {
                    ranks[i] = ranks[i - 1]; // Tie handling: same rank as previous
                } else {
                    ranks[i] = rank;
                }
                rank++;
            }
        return ranks;
        };

        // Sorting data for each ranking criterion
        const sortedByTotal = [...leaderboardData].sort((a, b) => b.totalPoints - a.totalPoints);
        const sortedByBracket = [...leaderboardData].sort((a, b) => b.bracketPoints - a.bracketPoints);
        const sortedByDailyPicks = [...leaderboardData].sort((a, b) => b.dailyPicksPoints - a.dailyPicksPoints);

        // Calculate ranks for each category
        const totalRanks = calculateRanks(sortedByTotal, 'totalPoints');
        const bracketRanks = calculateRanks(sortedByBracket, 'bracketPoints');
        const dailyPicksRanks = calculateRanks(sortedByDailyPicks, 'dailyPicksPoints');

        // Map ranks back to the original users
        const userRanks = leaderboardData.map(user => {
            const overallRank = totalRanks[sortedByTotal.findIndex(u => u === user)];
            const bracketRank = bracketRanks[sortedByBracket.findIndex(u => u === user)];
            const dailyPicksRank = dailyPicksRanks[sortedByDailyPicks.findIndex(u => u === user)];

            return {
                ...user,
                overallRank,
                bracketRank,
                dailyPicksRank
            };
        });

        return userRanks;
    };

    useEffect(() => {
        // Early return if we don't have the necessary data
        if (!gameData?.subGroups || !fullLeaderboard || !partyId || !mainGameId) {
            // console.log("Missing required data", {
            //     hasSubGroups: !!gameData?.subGroups,
            //     hasLeaderboard: !!fullLeaderboard,
            //     partyId,
            //     mainGameId
            // });
            return;
        }
    
        if (partyId !== mainGameId) {

            //console.log("Party ID is not Main Game ID");

            // Find the relevant subgroup
            const filteredSubgroups = gameData.subGroups.filter(subgroup => subgroup.groupID === partyId);
            
            // If no subgroup found, keep current leaderboard
            if (!filteredSubgroups.length || !filteredSubgroups[0]?.groupMembers?.length) {
                //console.log("No valid subgroup or members found");
                return;
            }
    
            const filteredUserIds = filteredSubgroups[0].groupMembers;
    
            // Filter and ensure we have valid data
            const filteredLeaderboard = fullLeaderboard.filter(user => 
                user && filteredUserIds.includes(user.userId)
            );
    
            // Only proceed if we have valid filtered data
            if (!filteredLeaderboard.length) {
                //console.log("No valid filtered leaderboard data");
                return;
            }
    
            const rankedLeaderboard = assignSubgroupRanks(filteredLeaderboard);
            //console.log("Ranked Leaderboard:", rankedLeaderboard);
    
            setLeaderboard(rankedLeaderboard);
        } else {
            // For main game ID, set to full leaderboard with proper validation
            setLeaderboard(fullLeaderboard);
        }
    }, [partyId, fullLeaderboard, gameData?.subGroups, mainGameId]);


    const leaderboardForMyPoints = isTeamGame ? barBattleLeaderboard : fullLeaderboard;
    //console.log("Leaderboard for My Points:", leaderboardForMyPoints);
    const myInfo = getUserPointsAndRanks(myUserId, leaderboardForMyPoints);

    const [unsavedPicks, setUnsavedPicks] = useState(false);
    const [unsavedBracketPicks, setUnsavedBracketPicks] = useState(false);

    const { component, pageState, optionalState } = useParams();

    const location = useLocation();
    const navigateToTop = useNavigateToTop();
    const navigate = useNavigate();

    const [referralId, setReferralId] = useState(null);
    const [newSignUp, setNewSignUp] = useState(false);

    const [selectedUserToView, setSelectedUserToView] = useState(null);
    //console.log("Selected User to View:", selectedUserToView);

    useEffect(() => {
        if (component === 'BRACKET' && leaderboardData?.length > 0) {
            const selectedUserId = pageState;
            const individualUser = leaderboardData?.find(user => user.userId === selectedUserId);
            
            if (isTeamGame) {
                const teamUser = barBattleLeaderboard?.find(user => user.userId === selectedUserId);
                setSelectedUserToView(teamUser || individualUser || []);
            } else {
                setSelectedUserToView(individualUser || []);
            }
        }
    }, [component, optionalState, leaderboardData, pageState, isTeamGame, barBattleLeaderboard]);
    

    useEffect(() => {
        //console.log("Running Referral Use Effect");
        const queryParams = new URLSearchParams(location.search);
        const referralId = queryParams.get('referralId');
        const newSignUp = queryParams.get('signUp');
        if (referralId) {
            setReferralId(referralId);
        }
        if (newSignUp) {
            setNewSignUp(true);
        }
    }, [location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const fromJoinCard = queryParams.get('fromJoinCard');
        if (fromJoinCard && ((hasJoinDeadlinePassed && !doesUserBelong) || isGameOver)) {
            navigateToTop(`/`);
        }
    }, [location.search, hasJoinDeadlinePassed, doesUserBelong, isGameOver, navigateToTop]);

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward =>
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );
    //console.log("Any Unredeemed Rewards:", anyUnredeemedRewards);

    
    const hasBracketDeadlinePassed = DateTime.fromISO(countdownDeadline, { zone: 'America/New_York' }) < timeNowInNewYork;
    //console.log("Has Bracket Deadline Passed:", hasBracketDeadlinePassed);
    //console.log("Countdown Deadline:", countdownDeadline, "Time Now in New York:", timeNowInNewYork);

    useEffect(() => {
        //console.log("Updating Click State");
        //Update the clickState whenever the URL changes
        if (component) {
            setClickState(component);
        }
    }, [component, location, setClickState]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorCircleLogo = gameData?.sponsorInfo?.sponsorCircleLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    const widerLogo = gameData?.sponsorInfo?.widerLogo;
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    const hideSponsorInPoolHall = gameData?.sponsorInfo?.hideSponsorInPoolHall;

    const getSuperPickemWinnersByRank = (users = []) => {
        // Group users based on their ranks
        const winners = {
          superPickemWinners: users
            .filter(user => user.overallRank === 1) // Get all users with overallRank = 1
            .map(user => user.username), // Extract usernames
          bracketWinners: users
            .filter(user => user.bracketRank === 1) // Get all users with bracketRank = 1
            .map(user => user.username), // Extract usernames
          pickemWinners: users
            .filter(user => user.dailyPicksRank === 1) // Get all users with dailyPicksRank = 1
            .map(user => user.username), // Extract usernames
        };
      
        return winners;
    };

    const { superPickemWinners, bracketWinners, pickemWinners } = getSuperPickemWinnersByRank(fullLeaderboard);
    //console.log("Super Pickem Winners:", superPickemWinners, "Bracket Winners:", bracketWinners, "Pickem Winners:", pickemWinners);
    
    //MAKE THESE DYNAMIC ONCE LEADERBOARD IS SET UP
    const whosInFirst = "Winner";
    const whosInFirstCharacterCount = 6;
    const whosInSecond = "Runner-Up";
    const whosInSecondCharacterCount = 8;
    const whosInThird = "Third Place";
    const whosInThirdCharacterCount = 10;
    const howManyWinners = 3;

    const homePageProps = { signUpState, setSignUpState, gameAddress, userData, gameData, setInterval, currentInterval: currentRound, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners, referralId, countdownTimer, countdownMessage, timerDateTime: countdownDeadline, fullLeaderboard, referralPrizes, amIOptedInToTexts, countdownText, countdownBackground, firstBowlTime, bowlPicks: true, isHostGame, isTeamGame, sponsorAbbreviation, hostGameData, sponsorCircleLogo, barBattleLeaderboard, tShirt, tShirtReward }

    //console.log("Picks By Interval:", picksByInterval, "interval:", interval);
    const picksThisInterval = picksByInterval?.find(picks => picks.interval === interval)?.picks;
    //console.log("Picks This Interval:", picksThisInterval);

    const picksThisIntervalInDB = userPicksFromDB?.filter(pick => pick.interval === interval);
    const picksThisIntervalInState = pickState?.filter(pick => pick.interval === interval);
    
    //Reset unsaved picks when game id changes
    useEffect(() => {
        //console.log("Resetting unsaved picks");
        if (mainGameId) {
            setUnsavedPicks(false);
        }
    }, [mainGameId]);

    const handleClickState = (click) => {
        //console.log("Handle Click State: ", click);
        setClickState(click);
        let pageStateToUse = partyId;
        if (click === 'MY_PICKS') {
          pageStateToUse = 'CURRENT';
          setInterval(currentRound);
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        } else if (click === 'HOME') {
          pageStateToUse = 'HOME';
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        }

        if (click === 'MY_PARTY' && userSubgroups.length === 0) {
          pageStateToUse = 'NO_PARTY';
        } else if (click === 'MY_PARTY' && partyId === mainGameId) {
            //console.log("Party ID is Main Game ID");
          if (defaultPartyName !== 'Pool Party' && defaultPartyName !== sponsorName) {
            pageStateToUse = defaultPartyId;
            setPartyState(defaultPartyName);
            setPartyId(defaultPartyId);
          } else {
            pageStateToUse = firstSubgroupId;
            setPartyState(firstSubgroupName);
            setPartyId(firstSubgroupId);
          }
        }

        if (click === 'LEADERBOARD' || click === 'POOL_PICKS') {
            pageStateToUse = partyId;
            setPartyState(partyState);
            setPartyId(partyId);
        }

        if (click === 'REWARDS') {
          pageStateToUse = 'ELIGIBLE';
        }

        if (click === 'MEMBERS') {
            pageStateToUse = mainGameId;
        }
    
        if (click === 'PRESENTED_BY') {
          pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
        }
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
      }



    return (
        <div>
            {sponsorLogo && (
                <GameSponsorHeader
                    sponsorLogo={sponsorHeaderLogo} 
                    sponsorBannerText={sponsorBannerText} 
                    sponsorName={sponsorName}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    myUserId={myUserId}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    userPicksFromDB={userPicksFromDB}
                    setPickState={setPickState}
                    setPicksToEmpty={true}
                    sponsorAbbreviation={sponsorAbbreviation}
                    setBracketState={setBracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedBracketPicks={unsavedBracketPicks}
                    setUnsavedBracketPicks={setUnsavedBracketPicks}
                    dontShowJoinButton={true}
                    widerLogo={widerLogo}
                    countdownBackground={countdownBackground}
                    isTeamGame={isTeamGame}
                    sponsorCircleLogo={sponsorCircleLogo}
                    logo={logo}
                    isHostGame={isHostGame}
                />
            )}

            {anyUnredeemedRewards && 
                <RewardsRedeemHeader
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                />
            }

            <DynamicPoolHeaderBar
                clickState={clickState}
                navigationText={'Home'}
                headerText={gameName}
                gameAddress={gameAddress}
                gameId={mainGameId}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                setBracketState={setBracketState}
                setPicksToEmpty={true}
                userBracketPicksFromDB={userBracketPicksFromDB}
                unsavedBracketPicks={unsavedBracketPicks}
                setUnsavedBracketPicks={setUnsavedBracketPicks}
            />

            {(!doesUserBelong) && (
                <GameLogoDisplay
                    doesUserBelong={doesUserBelong}
                    gameName={gameName}
                    logo={logo}
                    gameLogoDisplayInfo={gameLogoDisplayInfo}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    isHostGame={isHostGame}
                    affiliatedGamesInfo={affiliatedGamesInfo}
                    gameAddress={gameAddress}
                    isTeamGame={isTeamGame}
                />
            )}

            {myUsername && doesUserBelong &&
                <SuperPickemUserDash
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    doesUserBelong={doesUserBelong}
                    myUsername={myUsername}
                    gameData={gameData}
                    setJoinOpen={setJoinOpen}
                    anyUnredeemedRewards={anyUnredeemedRewards}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    formattedGameStartTime={formattedGameStartTime}
                    myInfo={myInfo}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    teams={nflTeams}
                    howManyRounds={howManyRounds}
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    firstGameTime={firstBowlTime}
                    bracketPicksToMake={bracketPicksToMake}
                    myUserId={myUserId}
                    totalEntrants={totalEntrants}
                    userInSubgroups={userSubgroups?.length > 0}
                    isTeamGame={isTeamGame}
                    sponsorAbbreviation={sponsorAbbreviation}
                    isBracketReady={isBracketReady}
                    gatherLogo={gatherLogoNFL}
                />
            }

            <SuperPickemNav
                setClickState={handleClickState}
                clickState={clickState}
                setSignUpState={setSignUpState}
                doesUserBelong={doesUserBelong}
                isGameOver={isGameOver}
                userSubgroups={userSubgroups}
                sponsorAbbreviation={sponsorAbbreviation}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                setBracketState={setBracketState}
                unsavedBracketPicks={unsavedBracketPicks}
                setUnsavedBracketPicks={setUnsavedBracketPicks}
                userBracketPicksFromDB={userBracketPicksFromDB}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                isTeamGame={isTeamGame}
                isHostGame={isHostGame}
                isBracketReady={isBracketReady}
            />

            {tiebreakerGameInfo?.length > 0 &&
                <TiebreakerGameButtons
                    myTiebreakerGames={tiebreakerGameInfo}
                    gameName={gameName}
                />
            }

            {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'POOL_PICKS')) &&
                <PartyDropdown
                    userSubgroups={userSubgroups}
                    gameAddress={gameAddress}
                    partyState={partyState}
                    setPartyState={setPartyState}
                    clickState={clickState}
                    setPartyId={setPartyId}
                    sponsorName={sponsorName}
                />
            }

            {isGameOver && (clickState === 'HOME') && (
                <SuperPickemWinnersDisplay
                    superPickemWinners={superPickemWinners}
                    bracketWinners={bracketWinners}
                    pickemWinners={pickemWinners}
                    partyName={sponsorName}
                    gameName={gameName}
                    partyId={mainGameId}
                    mainGameId={mainGameId}
                    titleColor={countdownBackground}
                />
            )}

            {clickState === 'PRESENTED_BY' && <SponsorTab gameData={gameData} />}

            {clickState === 'HOME' && <GameHomePageNew homePageProps={homePageProps} /> }

            {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

            {clickState === 'REWARDS' && 
                <QuickPickRewards
                    rewards={rewards}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    sponsorHeaderLogo={sponsorHeaderLogo}
                    sponsorName={sponsorName}
                    intervalType={gameData?.intervalType}
                    schedule={schedule}
                    myUserId={myUserId}
                />
            }

            {clickState === 'REFERRAL_LEADERBOARD' &&
                <ReferralsLeaderboard
                    gameReferrals={gameReferrals}
                    myUsername={myUsername}
                    referralPrizes={referralPrizes}
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                    myUserId={myUserId}
                />
            }

            {clickState === 'MY_PICKS' &&
                <SuperPickemMyPicks
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={nflTeams}
                    names={nflTeams}
                    stadiums={nflStadiums}
                    schedule={schedule}
                    games={combinedSchedule}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    highestRound={highestRound}
                    picking={picking}
                    gameDescription={gameDescription}
                    pickState={pickState}
                    setPickState={setPickState}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    interval={interval}
                    setInterval={setInterval}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    league={league}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    picksThisIntervalInState={picksThisIntervalInState}
                    season={season}
                    logo={logo}
                    gatherLogo={gatherLogoNFL}
                    gatherStadiumInfo={gatherStadiumInfoNFL}
                    gatherDisplayInfo={gatherDisplayInfoNFL}
                    gatherScores={gatherScoresNFL}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayNFL}
                    gatherPickEmCardTopLeftInfo={gatherPickEmCardTopLeftInfoNFL}
                    pickemLogo={pickemLogo}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    hideSponsorInPoolHall={hideSponsorInPoolHall}
                    regularSeasonSchedule={nflScoresBySeason}
                    gatherModalInfo={gatherModalInfoNFL}
                    standings={nflStandings}
                    showMyPicksBar={showMyPicksBar}
                />
            }

            {clickState === 'LEADERBOARD' &&
                <SuperPickemLeaderboard
                    leaderboard={leaderboard}
                    myUserId={myUserId}
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    teams={nflTeams}
                    gatherLogo={gatherLogoNFL}
                    tiebreakerInfo={tiebreakerInfo}
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                    selectedUserToView={selectedUserToView}
                    setSelectedUserToView={setSelectedUserToView}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    highestRound={highestRound}
                    firstBowlTime={firstBowlTime}
                    hostIds={hostIds}
                    maxBracketPoints={maxBracketPoints}
                    sponsorAbbreviation={sponsorAbbreviation}
                    partyId={partyId}
                    partyState={partyState}
                    isTeamGame={isTeamGame}
                    sponsorName={sponsorName}
                    hostGameData={hostGameData}
                    barBattleLeaderboard={barBattleLeaderboard}
                    hasFinalStarted={hasFinalStarted}
                    isGameOver={isGameOver}
                    gameName={gameName}
                    countdownBackground={countdownBackground}
                />
            }    

            {clickState === 'POOL_PICKS' &&
                <SuperPickemPoolPicks
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                    currentInterval={currentRound}
                    games={combinedSchedule}
                    rounds={rounds}
                    seeds={seeds}
                    leaderboard={leaderboard}
                    teams={nflTeams}
                    myUserId={myUserId}
                    schedule={schedule}
                    gatherLogo={gatherLogoNFL}
                    teamsOrNames={nflTeams}
                    getPickStatsGameInfo={getPickStatsGameInfoNFL}
                    getPickStatsPickInfo={getPickStatsPickInfoNFL}
                    getChampInfo={getBracketInfoPostLockNFL}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    countdownDeadline={countdownDeadline}
                    pickemLogo={pickemLogo}
                    userPicksFromDB={userPicksFromDB}
                    hostIds={hostIds}
                    hostBackgroundColor={hostBackgroundColor}
                    sponsorAbbreviation={sponsorAbbreviation}
                    partyId={partyId}
                    gatherPoolPicksHeaderInfo={gatherPoolPicksHeaderInfoNFL}
                    processChampStats={processChampStatsNFL}
                    isTeamGame={isTeamGame}
                    sponsorName={sponsorName}
                    barBattleLeaderboard={barBattleLeaderboard}
                    firstBowlTime={firstBowlTime}
                />
            }     

            {clickState === 'MY_BRACKET' && !hasBracketDeadlinePassed && 
                <EightTeamBracket
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={nflTeams}
                    names={nflTeams}
                    stadiums={nflStadiums}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    standings={nflStandings}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    season={season}
                    league={league}
                    logo={sponsorSecondaryLogo}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    gatherLogo={gatherLogoNFL}
                    gatherStadiumInfo={gatherStadiumInfoNFL}
                    gatherDisplayInfo={gatherDisplayInfoNFL}
                    gatherScores={gatherScoresNFL}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayNFL}
                    unsavedBracketPicks={unsavedBracketPicks}
                    setUnsavedBracketPicks={setUnsavedBracketPicks}
                    getBracketInfoPreLock={getBracketInfoPreLockNFL}
                    countdownDeadline={countdownDeadline}
                    bracketLogo={bracketLogo}
                    bracketTiebreakerInfo={bracketTiebreakerInfo}
                    bracketPicksToMake={bracketPicksToMake}
                    topBracketLogo={topBracketLogo}
                    bottomBracketLogo={bottomBracketLogo}
                    showMyBracketModal={showMyBracketModal}
                />
            }

            {clickState === 'MY_BRACKET' && hasBracketDeadlinePassed &&
                <EightTeamBracketLive
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    teams={nflTeams}
                    names={nflTeams}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    rounds={rounds}
                    bracketState={bracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    getBracketInfoPostLock={getBracketInfoPostLockNFL}
                    topBracketLogo={topBracketLogo}
                    bottomBracketLogo={bottomBracketLogo}
                    bracketLogo={bracketLogo}
                />
            }

            {clickState === 'BRACKET' && !hasBracketDeadlinePassed &&
                <Typography 
                    sx={{ 
                        fontSize: '18px', 
                        fontWeight: 700, 
                        marginTop: '25px', 
                        textAlign: 'center' // Optional, adds better alignment for centered content
                    }}
                >
                    Entrants' brackets are not viewable yet. <br/> Please check back after the first game has started.
                </Typography>
            
            }

            {clickState === 'BRACKET' && hasBracketDeadlinePassed &&
                <EightTeamBracketLive
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    teams={nflTeams}
                    names={nflTeams}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    rounds={rounds}
                    bracketState={selectedUserToView?.bracketPicks || []}
                    userBracketPicksFromDB={selectedUserToView?.bracketPicks || []}
                    getBracketInfoPostLock={getBracketInfoPostLockNFL}
                    usernameForDisplay={selectedUserToView?.username}
                    topBracketLogo={topBracketLogo}
                    bottomBracketLogo={bottomBracketLogo}
                    bracketLogo={bracketLogo}
                />
            }

            {clickState === 'MY_PARTY' &&
                <MyParty
                    gameData={gameData}
                    myUserId={myUserId}
                    handleCreateSubgroup={handleCreateSubgroup}
                    userSubgroups={userSubgroups}
                    signUpState={signUpState}
                    setSignUpState={setSignUpState}
                    gameAddress={gameAddress}
                    leaderboardData={leaderboard}
                    partyState={partyState}
                    partyId={partyId}
                    defaultPartyId={defaultPartyId}
                    fullLeaderboard={leaderboardData}
                    doesUserBelong={doesUserBelong}
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    isGameOver={isGameOver}
                />    
            }

            {joinOpen && !isHostGame && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    sponsorName={sponsorName}
                    sponsorLogo={sponsorLogo}
                    mainGameId={mainGameId}
                    joinModalText={gameData?.joinModalText}
                    referralId={referralId}
                    newSignUp={newSignUp}
                    hideSponsorInPoolHall={hideSponsorInPoolHall}
                    isTeamGame={isTeamGame}
                    hostGameData={hostGameData}
                    sponsorCircleLogo={sponsorCircleLogo}
                    sponsorAbbreviation={sponsorAbbreviation}
                    tShirtReward={tShirtReward}
                    tShirt={tShirt}
                    countdownBackground={countdownBackground}
                />
            )}

            {joinOpen && isHostGame && (
                <JoinBarBattleModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    sponsorName={sponsorName}
                    sponsorLogo={sponsorLogo}
                    mainGameId={mainGameId}
                    joinModalText={gameData?.joinModalText}
                    referralId={referralId}
                    newSignUp={newSignUp}
                    hideSponsorInPoolHall={hideSponsorInPoolHall}
                    affiliatedGamesInfo={affiliatedGamesInfo}
                    isTeamGame={isTeamGame}
                    isHostGame={isHostGame}
                    hostGameData={hostGameData}
                    sponsorCircleLogo={sponsorCircleLogo}
                    sponsorAbbreviation={sponsorAbbreviation}
                    countdownBackground={countdownBackground}
                />
            )}

            {userAlreadyInOtherGame && (
                <AlreadyInBattleModal
                    logo={logo}
                    gameAddress={gameAddress}
                    affiliatedGamesInfo={affiliatedGamesInfo}
                    affiliatedGame={affiliatedGame}
                    userAlreadyInOtherGame={userAlreadyInOtherGame}
                    setUserAlreadyInOtherGame={setUserAlreadyInOtherGame}
                />
            )}

            {timeToShowTiebreaker && !hasUserSeenTiebreakerModal && (
                <SuperPickemTiebreakerModal
                    myTiebreakerGames={tiebreakerGameInfo}
                />
            )}
        </div>
    );
}

export default NFLSuperPickemContainer;