import React, { useState } from "react";
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useParams } from 'react-router-dom';
import DynamicModal from "./DynamicModal";
import ResponseModal from "./ResponseModal";
//import { usePromoteDemoteCommissioner } from "../../hooks/commissioner/usePromoteDemoteCommissioner";
//import { useAddRemoveUserFromGame } from "../../hooks/commissioner/useAddRemoveUserFromGame";
//import { useAddPaidUsers } from "../../hooks/commissioner/useAddPaidUsers";
import { usePromoteDemoteCommissioner, useAddRemoveUserFromGame, useAddPaidUsers } from "../../hooks/commissioner/index";

const ManageMembers =({
    gameData,
    myUserId,
    gameCommissioners,
    gameAdministrator,
    leaderboardInfo,
    mainGameId,
    gameName,
    isGameOver,
    doIHaveCommissionerAccess,
    gameAddress,
}) => {
    const [modalState, setModalState] = useState(null); // State for modal
    const [modalUserInfo, setModalUserInfo] = useState(null); // State for modal user info
    console.log("Modal State: ", modalState, "Modal User Info: ", modalUserInfo);

    // In your ManageMembers component, add these new states:
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [addInfoState, setAddInfoState] = useState(false);

    const { mutate: promoteDemoteCommissioner } = usePromoteDemoteCommissioner();
    const { mutate: addRemoveUserFromGame } = useAddRemoveUserFromGame();
    const { mutate: addPaidUsers } = useAddPaidUsers();

    const myInfo = {userId: myUserId};

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const groupLength = leaderboardInfo?.length;

    const paidUsers = gameData?.gameParameters[0]?.paidUsers;
    const [paidUsersArray, setPaidUsersArray] = useState(paidUsers || []);

    const amIAdministrator = gameAdministrator?.includes(myUserId);
    const amICommissioner = gameCommissioners?.includes(myUserId);
    console.log("game Administrator: ", gameAdministrator, "game Commissioners: ", gameCommissioners);
    console.log("Am I Admin: ", amIAdministrator, "Am I Commish: ", amICommissioner);



    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const commissionerLogo = (
        <div className="commissioner-logo-circle">
            H
        </div>
    );

    const handlers = {
        handleAddCommissioner: (userInfo) => {
            promoteDemoteCommissioner({
                gameId: mainGameId,
                memberId: userInfo.userId,
                addOrRemove: 'add',
                commissionerId: myUserId
            }, {
                onSuccess: () => {
                    setResponseStatus('success');
                    setResponseMessage(`${userInfo.username} has been added as a commissioner.`);
                    setResponseModalOpen(true);
                },
                onError: (error) => {
                    setResponseStatus('error');
                    setResponseMessage(error.message || 'Failed to add commissioner.');
                    setResponseModalOpen(true);
                }
            });
        },
        handleRemoveCommissioner: (userInfo) => {
            promoteDemoteCommissioner({
                gameId: mainGameId,
                memberId: userInfo.userId,
                addOrRemove: 'remove',
                commissionerId: myUserId
            }, {
                onSuccess: () => {
                    setResponseStatus('success');
                    setResponseMessage(`${userInfo.username} has been removed as a commissioner.`);
                    setResponseModalOpen(true);
                },
                onError: (error) => {
                    setResponseStatus('error');
                    setResponseMessage(error.message || 'Failed to remove commissioner.');
                    setResponseModalOpen(true);
                }
            });
        },
        handleRemoveUser: (userInfo, isLeaving) => {
            addRemoveUserFromGame({
                gameId: mainGameId,
                memberId: userInfo.userId,
                username: userInfo.username,
                userFullName: userInfo.userFullName,
                addOrRemove: 'remove',
                commissionerId: myUserId,
            }, {    
                onSuccess: () => {
                    setResponseStatus('success');
                    setResponseMessage(`${userInfo.username} has been removed from the pool.`);
                    setResponseModalOpen(true);
                },
                onError: (error) => {
                    setResponseStatus('error');
                    setResponseMessage(error.message || 'Failed to remove user.');
                    setResponseModalOpen(true);
                }
            });
        },
        handleReInstateUser: (userInfo) => {
            addRemoveUserFromGame({
                gameId: mainGameId,
                memberId: userInfo.memberId,
                username: userInfo.username,
                userFullName: userInfo.userFullName,
                addOrRemove: 'add',
                commissionerId: myUserId,
            }, {    
                onSuccess: () => {
                    setResponseStatus('success');
                    setResponseMessage(`${userInfo.username} has been\nre-instated to the pool.`);
                    setResponseModalOpen(true);
                },
                onError: (error) => {
                    setResponseStatus('error');
                    setResponseMessage(error.message || 'Failed to re-instate user.');
                    setResponseModalOpen(true);
                }
            });
        },
        handleDeleteGame: (userInfo) => {
          console.log("Delete Game: ", userInfo);
        },
        handleAddInfoClick: () => {
            if (addInfoState) {
                addPaidUsers({
                    gameId: mainGameId,
                    paidUsersArray,
                    commissionerId: myUserId
                }, {
                    onSuccess: () => {
                        setResponseStatus('success');
                        setResponseMessage('Paid user info saved successfully');
                        setResponseModalOpen(true);
                        setAddInfoState(false);
                    },
                    onError: (error) => {
                        setResponseStatus('error');
                        setResponseMessage(error.message || 'Failed to save paid user info');
                        setResponseModalOpen(true);
                    }
                });
            } else {
                setAddInfoState(!addInfoState);
            }
        }
    };

    const closeModal = () => {
        setModalState(null);
        setModalUserInfo(null);
    };

    const handlePaidStatusChange = (userId) => {
        setPaidUsersArray(prev => {
            if (prev.includes(userId)) {
                return prev.filter(id => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const handleCancel = () => {
        setPaidUsersArray(paidUsers);
        setAddInfoState(false);
    };

    const truncatedPartyState = gameName?.length > 25 ? `${gameName.slice(0, 25)}...` : gameName;

    const removedUsers = gameData?.gameParameters[0]?.removedUsers;
    console.log("Removed Users:", removedUsers);

    return (
        <div>
            <Typography 
                variant="h2" 
                style={{ 
                    fontSize: isMobile ? '20px' : '28px', 
                    fontWeight: '700', 
                    marginTop:'32px',
                    marginBottom: '32px', 
                }}
            >
                {(amICommissioner || amIAdministrator) ? "Manage" : "Pool"} Members ({groupLength})
            </Typography>

            {(amIAdministrator || amICommissioner) && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlers.handleAddInfoClick}
                        sx={{ marginBottom: '20px' }}
                    >
                        {!addInfoState ? 'Add Member Info' : 'Save Info'}
                    </Button>
                    {addInfoState && (
                        <Button
                            variant="contained"
                            onClick={handleCancel}
                            sx={{ marginLeft: '10px', marginBottom: '20px' }}
                        >
                            Cancel
                        </Button>
                    )}
                </>
            )}

            <TableContainer 
                component={Paper} 
                style={{ 
                    width:'100%', 
                    maxWidth:'800px', 
                    margin:'0 auto', 
                    borderRadius:'0px', 
                    borderLeft:"2px solid #002129",
                    borderRight:"2px solid #002129",
                }}
            >
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#002129', borderBottom: "2px solid #002129" }}>
                        <TableRow>
                            <TableCell 
                                className='private-commissioner-table' 
                                sx={{ 
                                    color: 'white', 
                                    fontSize: isMobile ? '14px' : '20px', 
                                    fontWeight: '700' 
                                }}
                            >
                                Player
                            </TableCell>
                            {!addInfoState ? (
                                <>
                                    <TableCell 
                                        className='private-commissioner-table' 
                                        align="center" 
                                        sx={{ 
                                            color: 'white', 
                                            fontSize: isMobile ? '14px' : '20px', 
                                            fontWeight: '700' 
                                        }}
                                    >
                                        Host
                                    </TableCell>
                                    {(amICommissioner || amIAdministrator) && (
                                        <TableCell 
                                            className='private-commissioner-table' 
                                            align="center" 
                                            sx={{ 
                                                color: 'white', 
                                                fontSize: isMobile ? '14px' : '20px', 
                                                fontWeight: '700' 
                                            }}
                                        >
                                            Remove
                                        </TableCell>
                                    )}
                                </>
                            ) : (
                                <TableCell 
                                    className='private-commissioner-table' 
                                    align="center" 
                                    sx={{ 
                                        color: 'white', 
                                        fontSize: isMobile ? '14px' : '20px', 
                                        fontWeight: '700' 
                                    }}
                                >
                                    Paid?
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {leaderboardInfo?.map((leaderboardInfo, index) => (
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                            >
                                <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <span>{leaderboardInfo?.userFullName}</span>
                                        {(amICommissioner || amIAdministrator) && paidUsersArray?.includes(leaderboardInfo?.userId) && (
                                            <AttachMoneyIcon sx={{ color: '#00AA72', fontSize: '20px' }} />
                                        )}
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                        {leaderboardInfo?.username}
                                    </div>
                                </TableCell>

                                {!addInfoState ? (
                                    <>
                                        <TableCell className='private-commissioner-table' align="center" sx={{ padding: '0px'}}>
                                            {(gameAdministrator?.includes(leaderboardInfo?.userId) || gameCommissioners?.includes(leaderboardInfo?.userId)) ? (
                                                <>
                                                    {commissionerLogo}
                                                    {!gameAdministrator?.includes(leaderboardInfo?.userId) && (amICommissioner || amIAdministrator) && (
                                                        <RemoveIcon
                                                            sx={{ color: '#CC293C', cursor: 'pointer', marginLeft: isMobile ? '5px' : '10px' }}
                                                            onClick={() => { setModalUserInfo(leaderboardInfo); setModalState("confirmRemoveCommissioner")}}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                (amICommissioner || amIAdministrator) && (
                                                    <AddIcon
                                                         sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                         onClick={() => { setModalUserInfo(leaderboardInfo); setModalState("confirmAdd")}}
                                                    />
                                                )
                                            )}
                                        </TableCell>

                                        {(amICommissioner || amIAdministrator) && (
                                            <TableCell className='private-commissioner-table' align="center">
                                                {gameAdministrator?.includes(leaderboardInfo?.userId) ? (
                                                    <div>N/A</div>
                                                ) : (
                                                    <CloseIcon
                                                        sx={{ color: '#CC293C', cursor: 'pointer' }}
                                                        onClick={() => {setModalUserInfo(leaderboardInfo); setModalState("confirmRemoveUser")}}
                                                    />
                                                )}
                                            </TableCell>
                                        )}
                                    </>
                                ) : (
                                    <TableCell className='private-commissioner-table' align="center">
                                        <Checkbox
                                            checked={paidUsersArray.includes(leaderboardInfo?.userId)}
                                            onChange={() => handlePaidStatusChange(leaderboardInfo?.userId)}
                                            sx={{
                                                color: '#002129',
                                                '&.Mui-checked': {
                                                    color: '#00AA72',
                                                },
                                            }}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
            </TableContainer>

            {removedUsers?.length > 0 && (
                <>
                    <Typography 
                        variant="h2" 
                        style={{ 
                            fontSize: isMobile ? '20px' : '28px', 
                            fontWeight: '700', 
                            marginTop:'32px',
                            marginBottom: '32px', 
                        }}
                    >
                        Removed Members ({removedUsers?.length})
                    </Typography>

                    <TableContainer 
                        component={Paper} 
                        style={{ 
                            width:'100%', 
                            maxWidth:'800px', 
                            margin:'0 auto', 
                            borderRadius:'0px', 
                            borderLeft:"2px solid #002129",
                            borderRight:"2px solid #002129",
                        }}
                    >
                        <Table aria-label="removed members table">
                            <TableHead sx={{ backgroundColor: '#002129', borderBottom: "2px solid #002129" }}>
                                <TableRow>
                                    <TableCell 
                                        className='private-commissioner-table' 
                                        sx={{ 
                                            color: 'white', 
                                            fontSize: isMobile ? '14px' : '20px', 
                                            fontWeight: '700' 
                                        }}
                                    >
                                        Player
                                    </TableCell>
                                    {(amICommissioner || amIAdministrator) && (
                                        <TableCell 
                                            className='private-commissioner-table' 
                                            align="center" 
                                            sx={{ 
                                                color: 'white', 
                                                fontSize: isMobile ? '14px' : '20px', 
                                                fontWeight: '700' 
                                            }}
                                        >
                                            Restore
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {removedUsers?.map((user, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                                    >
                                        <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    fontSize: '12px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {user.userFullName}
                                            </div>
                                            <div style={{ fontSize: '10px' }}>
                                                {user.username}
                                            </div>
                                        </TableCell>

                                        {(amICommissioner || amIAdministrator) && (
                                            <TableCell className='private-commissioner-table' align="center">
                                                <AddIcon
                                                    sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                    onClick={() => { 
                                                        setModalUserInfo(user); 
                                                        console.log("User Info: ", user);
                                                        setModalState("confirmReinstateUser");
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
                    </TableContainer>
                </>
            )}

            
            {/* {amIAdministrator ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(mainGameId); setModalState("confirmDelete")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    DELETE POOL
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(myInfo); setModalState("confirmLeave")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    LEAVE POOL
                </Button>
            )} */}

            <Box sx={{ height: '32px' }} />

            <DynamicModal
              open={!!modalState}
              onClose={closeModal}
              modalState={modalState}
              modalUserInfo={modalUserInfo}
              gameName={gameName}
              myUserId={myUserId}
              handlers={handlers}
            />

            <ResponseModal
              open={responseModalOpen}
              onClose={() => setResponseModalOpen(false)}
              status={responseStatus}
              message={responseMessage}
            />
               
        </div>
            
    );
}

export default ManageMembers;
