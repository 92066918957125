import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useAddPaidUsers = () => {
    return useMutation({
        mutationFn: addPaidUsersRequest,
        onError: (error) => {
        console.error("Error adding paid users:", error);
        },
        onSuccess: (data) => {
        console.log("Paid users added successfully:", data);
        },
    });
};

const addPaidUsersRequest = async (paidUsersData) => {
    console.log("Paid Users Data:", paidUsersData);
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage
    console.log(paidUsersData);
    const response = await axios.post(
        `${apiUrl}/user/add-paid-users`,
        paidUsersData,
        {
        headers: {
            Authorization: authorizationHeader, // Include the authorization header in the request
            "Content-Type": "application/json",
        },
        }
    );
    return response.data;
};  