import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const Lists = ({ title, list }) => {
  const handleClick = (link) => {
    if (link) window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 'md', margin: '0 auto' }}>
      {title && (
        <Typography
          variant="h6"
          sx={{
            fontSize: title?.fontSize || '16px',
            color: title?.color || '#002129',
            fontWeight: title?.weight || '700',
            textDecoration: title?.underline ? 'underline' : 'none',
            textAlign: 'center'
          }}
        >
          {title?.text}
        </Typography>
      )}
      
      <List 
        sx={{ 
          width: '100%',
          listStyleType: list.type === 'number' ? 'decimal' : 'disc',
          pl: 4,
          '& .MuiListItem-root': {
            display: 'list-item',
            padding: '0 0 8px 0'
          }
        }} 
      >
        {list?.items.map((item, index) => (
          <ListItem 
            key={index}
            {...(item?.link && {
              onClick: () => handleClick(item?.link),
              sx: { 
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            })}
          >
            <ListItemText
              primary={item?.text}
              sx={{
                '.MuiListItemText-primary': {
                  color: list.style?.color || '#002129',
                  fontSize: list.style?.fontSize || '14px',
                  fontWeight: list.style?.weight || '400'
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Lists;