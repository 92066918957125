import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

const getSharedGameIds = async ({ gameID }) => {
    const data = { gameID };
    const response = await axios.post(`${apiUrl}/games/get-shared-game-ids`, data, {
        headers: {
        "Content-Type": "application/json",
        },
    });
    
    return response.data;
};

export const useGetSharedGameIds = () => {
    return useMutation({
        mutationFn: getSharedGameIds,
        onError: (error) => {
            console.error("Error fetching game:", error);
        },
    });
};
