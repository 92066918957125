import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { DateTime } from 'luxon';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';
import WeeklyWinnerDisplay from '../../ChampionDisplay/WeeklyWinnerDisplay';
import Top3Display from '../../ChampionDisplay/Top3Display';
import Top3DisplayNew from '../../ChampionDisplay/Top3DisplayNew';
import QuickPickLeaderboardDropdown from './QuickPickLeaderboardDropdown';
import QuickPickNoGamesCard from '../CardPieces/QuickPickNoGamesCard';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModal';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';


const QuickPickLeaderboard = ({ 
    mainGameId, 
    myUserId, 
    myUsername, 
    isGameOver, 
    currentInterval, 
    firstInterval, 
    lastInterval, 
    intervalType, 
    handleClickState, 
    leaderboardData, 
    partyState, 
    schedule,
    gameAddress, 
    teamsToInclude,
    gatherDisplayBarInfo,
    sponsorName,
    league,
    teams,
    oneWeekEvent,
    tiebreakerInfo,
    gameName,
    quickPickGames,
    byeWeek,
    propIdsToUse,
    partyId,
    rankForUsersWithoutRank,
}) => {
  //console.log("Leaderbaord Data in Leaderboard", leaderboardData);
  
  const navigateToTop = useNavigateToTop();
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  const handleRulesClick = () => {
      navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
  }


  const [sortBy, setSortBy] = useState('rank');
  const [sortedColumn, setSortedColumn] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');
  const [leaderboardInterval, setLeaderboardInterval] = useState('');

  useEffect(() => {
    //console.log("Leaderboard Use Effect");
    if ((isGameOver && !oneWeekEvent) || oneWeekEvent) {
      setLeaderboardInterval('Season Standings');
    } else {
      let initialInterval = '';
      if (currentInterval && firstInterval && lastInterval) {
        initialInterval = currentInterval < firstInterval ? firstInterval : currentInterval > lastInterval ? lastInterval : currentInterval;
      }
      setLeaderboardInterval(initialInterval);
    }
  }, [currentInterval, firstInterval, lastInterval, isGameOver, oneWeekEvent]);
  
  // const [userStatsOpen, setUserStatsOpen] = useState(false);
  // const [userStatsInfo, setUserStatsInfo] = useState(null);
  const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);
  //console.log("SEASON VS WEEK", leaderboardInterval);

  // Add pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Add pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Add useEffect to reset page when filters change
  useEffect(() => {
    setPage(0);
  }, [leaderboardInterval, sortBy, sortOrder]);

  const thisWeeksGame = (leaderboardInterval === 'Season Standings' && currentInterval !== lastInterval) ? null : quickPickGames?.find((week) => week.Week === Number(leaderboardInterval === 'Season Standings' ? currentInterval : leaderboardInterval));
  const thisWeeksIdsToUse = propIdsToUse?.find((week) => week.interval === Number(leaderboardInterval === 'Season Standings' ? currentInterval : leaderboardInterval))?.propIdsToUse;
  const thisWeeksTiebreakerId = thisWeeksIdsToUse?.length > 0 ? thisWeeksIdsToUse[thisWeeksIdsToUse.length - 1] : null;
  //console.log("THIS WEEKs IDS TO USE", thisWeeksIdsToUse, "THIS WEEKS TIEBREAKER ID", thisWeeksTiebreakerId, "Prop Ids to Use in Leaderboard", propIdsToUse);
  //console.log("Leaderboard Interval", leaderboardInterval);
  const thisWeeksTiebreaker = thisWeeksGame?.props?.tiebreakers.find(
    (tiebreaker) => tiebreaker.id === thisWeeksTiebreakerId
  ) ?? null;
  //console.log("THIS WEEKS TIEBREAKER!", thisWeeksTiebreaker, "This Weeks Game", thisWeeksGame);
  const tiebreakerText = thisWeeksTiebreaker ? thisWeeksTiebreaker.propText : null;
  const tiebreakerAnswer = thisWeeksTiebreaker ? thisWeeksTiebreaker.correctOption : null;
  //console.log("THIS WEEKS GAME", thisWeeksGame, "thisWeeksTiebreaker", thisWeeksTiebreaker, "Text", tiebreakerText, "Answer", tiebreakerAnswer);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const seasonText = "Season Standings";

  let findTeamName;

  if (teamsToInclude && teamsToInclude.length > 0) {
      findTeamName = gatherDisplayBarInfo(teamsToInclude[0], teams);
  }    
  const teamName = findTeamName?.teamNickname;


  const getUserRank = (userData, partyId, rankForUsersWithoutRank) => {
    if (!userData?.rankings) {
      return rankForUsersWithoutRank;
    }
  
    const rankingEntry = userData.rankings.find(r => r.gameId === partyId);
    return rankingEntry ? rankingEntry.currentRank : rankForUsersWithoutRank;
  };


  const handleSort = (column) => {
    // If clicking the same column, toggle the sortOrder
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc'); // Reset to ascending when sorting a new column
    }
  };



  const assignRanks = (data) => {
    //console.log("ASSIGN RANKS", data);
    // Sort data in descending order by totalPoints and then by totalMargin
    data?.sort((a, b) => {
      const aPoints = a.totalPoints ?? 0;
      const bPoints = b.totalPoints ?? 0;
      const aMargin = (leaderboardInterval === 'Season Standings') ? a.finalMargin ?? 5000 :  a.totalMargin ?? 0;
      const bMargin = (leaderboardInterval === 'Season Standings') ? b.finalMargin ?? 5000 :  b.totalMargin ?? 0;
  
      // Sort by points first, then by margin (descending for both)
      if (aPoints !== bPoints) {
        return bPoints - aPoints; // Descending order for points
      } 
      return aMargin - bMargin; // Descending order for margin
    });
  
    let rank = 1;
    let tiedRank = 1;
    let previousPoints = data[0]?.totalPoints ?? 0;
    let previousMargin = (leaderboardInterval === 'Season Standings') ? data[0]?.finalMargin ?? 0 : data[0]?.totalMargin ?? 0;
  
    return data.map((entry, index) => {
      const currentPoints = entry.totalPoints ?? 0;
      const currentMargin = (leaderboardInterval === 'Season Standings') ? entry.finalMargin ?? 0 : entry.totalMargin ?? 0;
  
      // Determine rank for tied entries
      if (currentPoints === previousPoints && currentMargin === previousMargin) {
        entry.rank = tiedRank;
      } else {
        rank = index + 1; // Update rank to current index + 1
        tiedRank = rank; // Update tiedRank to current rank
        entry.rank = rank; // Assign current rank to entry
      }
  
      // Update previous values for next iteration
      previousPoints = currentPoints;
      previousMargin = currentMargin;
  
      return entry; // Return the updated entry
    });
  };
  


  let rankedData = leaderboardData;
  //console.log("RANKED DATA", rankedData);

  let weeklyLeaderboard = [];
  function findWeeklyLeaderboard() {
    const selectedWeek = leaderboardInterval;
    //console.log("SELECTED WEEK", selectedWeek);
    const weeklyLeaderboard = [];

    for (let i = 0; i < rankedData?.length; i++) {
        const user = rankedData[i];
        const username = user.username;
        const userId = user.userId;
        const picks = user.picks;
        const tiebreakers = user.tiebreakers;
        //console.log("PICKS SELECTED WEEK", picks);

        let wins = 0;
        let losses = 0;
        let points = 0;
        let totalMargin = 5000;

        if (picks) {
            for (let j = 0; j < picks.length; j++) {
                const game = picks[j];
                if (game.interval === selectedWeek) {
                    const result = game.result;
                    const gamePoints = game.points;
                    const isTiebreaker = game.tiebreaker;

                    if (result === 'Win') {
                        wins++;
                        points += gamePoints;
                    } else if (result === 'Loss' && game.teamId !== 'No Pick') {
                        losses++;
                    } 
                    if (isTiebreaker && game.margin !== null && game.margin !== undefined) {
                      totalMargin = game.margin;
                    }
                }
            }

        }

            // Create entry for the user
            const entry = {
                username,
                userId,
                totalWins: wins,
                totalLosses: losses,
                totalPoints: points,
                picks,
                totalMargin: totalMargin,
                tiebreakers,
            };

            // Push entry to leaderboard
            weeklyLeaderboard.push(entry);
        
    }

    return weeklyLeaderboard;
}
  const weeklyData = findWeeklyLeaderboard();
  //console.log("WEEKLY LEADERBOARD", weeklyData);

  const rankedWeeklyData = assignRanks(weeklyData);
  //console.log("RANKED WEEKLY DATA", rankedWeeklyData);


  const dataToSort = (leaderboardInterval === 'Season Standings') 
  ? rankedData 
  : assignRanks(rankedWeeklyData);
  //console.log("Data to Sort", dataToSort);

  const sortedData = [...dataToSort]?.sort((a, b) => {
    if (leaderboardInterval === 'Season Standings') {
      const aRank = getUserRank(a, partyId, Number.MAX_SAFE_INTEGER);
      const bRank = getUserRank(b, partyId, Number.MAX_SAFE_INTEGER);
      if (aRank !== bRank) {
        return sortOrder === 'asc' ? aRank - bRank : bRank - aRank;
      }
    }
  
    if (sortBy === 'username') {
      const nameA = a[sortBy].toUpperCase();
      const nameB = b[sortBy].toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    } else if (sortBy === 'totalWins') {
      const comparison = b[sortBy] - a[sortBy];
      return sortOrder === 'desc' ? -comparison : comparison;
    } else {
      const comparison = a[sortBy] - b[sortBy];
      return sortOrder === 'asc' ? comparison : -comparison;
    }
  });

  const thisIntervalsTiebreaker = (row) => {
    if (leaderboardInterval === 'Season Standings') {
        const tiebreakers = row?.tiebreakers;
        const thisTiebreaker = tiebreakers?.find(tiebreaker => tiebreaker.interval === currentInterval);
        const option = thisTiebreaker?.option;
        const margin = thisTiebreaker?.margin;
        const text = option !== undefined && option !== null
            ? `Tiebreaker: ${option}`
            : 'Tiebreaker: N/A';
        return text;
    } else {
        const tiebreakers = row?.tiebreakers;
        const thisTiebreaker = tiebreakers?.find(tiebreaker => tiebreaker.interval === leaderboardInterval);
        const option = thisTiebreaker?.option;
        const margin = thisTiebreaker?.margin; 
        const text = margin !== undefined && margin !== null 
            ? `Tiebreaker Margin: ${margin}` 
            : `Tiebreaker: ${option}`;
        return thisTiebreaker ? text : 'Tiebreaker: N/A';
    }
};

  
  const usersEntry = sortedData?.find(entry => entry.userId === myUserId);
  //console.log("USERS ENTRY in Leaderboard", usersEntry);



  const rankHeaderColor = sortedColumn === 'rank' ? '#FFC60A' : '#fff';
  const pointsHeaderColor = sortedColumn === 'points' ? '#FFC60A' : '#fff';
  const recordHeaderColor = sortedColumn === 'record' ? '#FFC60A' : '#fff';
  const playerHeaderColor = sortedColumn === 'username' ? '#FFC60A' : '#fff';

  const getRankedUsernames = (users = [], partyId) => {
    // Helper function to get the current rank for a specific partyId
    const getCurrentRank = (rankings = [], partyId) => {
      const ranking = rankings?.find(r => r.gameId === partyId);
      return ranking ? ranking.currentRank : null; // Return the rank if found, otherwise null
    };
  
    // Filter and group users based on their current rank for the given partyId
    const rankedUsernames = {
      first: users
        .filter(user => getCurrentRank(user.rankings, partyId) === 1)
        .map(user => user.username),
      second: users
        .filter(user => getCurrentRank(user.rankings, partyId) === 2)
        .map(user => user.username),
      third: users
        .filter(user => getCurrentRank(user.rankings, partyId) === 3)
        .map(user => user.username),
    };
  
    return rankedUsernames;
  };  

  const winners = getRankedUsernames(sortedData, partyId);
  //console.log("WINNERS", winners);

  //console.log("Sorted Data", sortedData);
  const whosInFirst = sortedData?.[0]?.username || null;
  const whosInFirstCharacterCount = whosInFirst?.length || 0;
 

  let selectedWeek = 1;
  if (leaderboardInterval !== 'Season Standings') {
    selectedWeek = leaderboardInterval;
  }
  const thisWeeksGames = schedule?.filter((week) => week.Week === Number(selectedWeek));
  const timeNowInNewYork = DateTime.now().setZone('America/New_York');
  //const hasWeekStarted = true;
  const hasWeekStarted = thisWeeksGames?.some((game) => timeNowInNewYork > DateTime.fromISO(game.DateTime));
  //console.log("Has Week Started", hasWeekStarted);
  const hasWeekEnded = thisWeeksGames?.every((game) => game.Status === "Final" || game.Status === "F/OT");
  //const hasWeekEnded = true;

  const isThisSegmentOver = (leaderboardInterval === 'Season Standings') ? isGameOver : hasWeekEnded;
  //console.log("This Weeks Games", thisWeeksGames, "Has Week Ended", hasWeekEnded, "Is Pick Em Over", isGameOver, "Is This Segment Over", isThisSegmentOver, "Selected Week:", selectedWeek, "Season vs Week", leaderboardInterval);

  const timeToRevealSeasonTiebreaker = (currentInterval === lastInterval && leaderboardInterval === 'Season Standings' && hasWeekStarted);
  //console.log("Current Interval", currentInterval, "Last Interval", lastInterval, "Leaderboard Interval", leaderboardInterval, "Has Game Started", hasWeekStarted, "Time To Reveal Season Tiebreaker", timeToRevealSeasonTiebreaker);

  // Modify the data slicing before mapping
  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (leaderboardInterval === '') {
    return <LoadingScreen />;
  }

  
  
  
  return (
    <>
      <Box>
          {!oneWeekEvent && (
            <QuickPickLeaderboardDropdown
              interval={leaderboardInterval}
              setInterval={setLeaderboardInterval}
              seasonText={seasonText}
              currentInterval={currentInterval}
              firstInterval={firstInterval}
              lastInterval={lastInterval}
              intervalType={intervalType}
            />
          )}
      
      {((leaderboardInterval === 'Season Standings' && isGameOver) || (oneWeekEvent && isGameOver)) && (
          <>
              {oneWeekEvent && (
                  <Box sx={{ marginTop: '10px' }}></Box>
              )}
              <Top3DisplayNew 
                rankedUsernames={winners}
                line2={`${gameName} Top 3`}
                partyName={partyState}
                gameName={gameName}
              />
          </>
      )}

    
      {leaderboardInterval !== 'Season Standings' && !oneWeekEvent && isThisSegmentOver && whosInFirst !== undefined && thisWeeksGames?.length !== 0 && (
          <WeeklyWinnerDisplay
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            selectedWeek={selectedWeek}
          />
      )}
      
       <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isGameOver || isThisSegmentOver ? '10px' : '0px',
                    marginBottom: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => setTiebreakInfoOpen(true)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        color: '#00AAD1',
                        marginRight: '5px',
                        marginTop: '10px',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <InfoIcon
                    sx={{
                        color: '#00AAD1',
                        fontSize: isMobile ? '16px' : '20px',
                    }}
                />
            </Box>

            {tiebreakerText && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginBottom: '10px',
                        padding: '0px 8px',
                    }}
                >
                    Tiebreaker: {tiebreakerText}
                </Typography>
            )}
            {tiebreakerAnswer && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginBottom: '10px',
                    }}
                >
                    Result: {tiebreakerAnswer}
                </Typography>
            )}

      {(leaderboardInterval === byeWeek) ? (
        <>
          <QuickPickNoGamesCard
            sponsorName={sponsorName}
            league={league}
            teamName={teamName}
            isThereAGame={false}
          />
          <Box sx={{ marginTop: '40px' }}></Box>
        </>
      ) : (
        <>
          <Box sx={{ maxWidth: '750px', margin: '0 auto' }}>
              <TablePagination
                rowsPerPageOptions={[50, 100]}
                component="div"
                count={sortedData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={Math.min(page, Math.max(0, Math.ceil((sortedData?.length || 0) / rowsPerPage) - 1))}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Rows"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiTablePagination-toolbar': {
                    color: '#002129',
                  },
                  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                    color: '#002129',
                  }
                }}
              />
            </Box>

      <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', marginBottom: '20px'}}>
        <Table>
          {usersEntry &&
            <TableHead sx={{ backgroundColor: '#F0F1F1'}}>
              <TableRow>
                <TableCell className="pickem-leaderboard-table-cells" style={{width:'57px', textAlign:'center', fontSize: isMobile ? '12px' : '14px'}}>
                {leaderboardInterval === 'Season Standings' 
                  ? getUserRank(usersEntry, partyId, rankForUsersWithoutRank)
                  : usersEntry?.rank ? usersEntry?.rank : rankForUsersWithoutRank}
                </TableCell>
                <TableCell
                    className="pickem-leaderboard-table-cells"
                    style={{ width: '160px', fontSize: isMobile ? '12px' : '16px' }}
                  >
                    <span
                      style={{ textDecoration: 'underline', fontWeight: 700 }}
                    >
                      {usersEntry?.username}
                    </span>
                    {hasWeekStarted && leaderboardInterval !== 'Season Standings' && (
                      <div style={{ fontSize: isMobile ? '12px' : '16px'}}>{thisIntervalsTiebreaker(usersEntry)}</div>
                    )}
                    {timeToRevealSeasonTiebreaker ? (
                      !hasWeekEnded ? (
                        <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                          {thisIntervalsTiebreaker(usersEntry)}
                        </div>
                      ) : (
                        <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                          Tiebreaker Margin: {(usersEntry?.finalMargin && usersEntry?.finalMargin !== 5000) ? usersEntry?.finalMargin : 'N/A'}
                        </div>
                      )
                    ) : null}

                  </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '81px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {usersEntry?.totalPoints ?? 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {`${usersEntry?.totalWins ?? 0}-${usersEntry?.totalLosses ?? 0}`}
                </TableCell>

              </TableRow>
            </TableHead>
        }
          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('rank');
                }}
                sx={{ width: '57px', color: rankHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                RANK
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('username');
                  setSortedColumn('username');
                }}
                sx={{ width: '160px', color: playerHeaderColor, fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'}}
              >
                PLAYER
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('points');
                }}
                sx={{ width: '81px', color: pointsHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'} }
              >
                {leaderboardInterval === "Season Standings" ? "TOT PTS" : "PTS"}
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('totalWins');
                  setSortedColumn('record');
                }}
                sx={{ width: '77px', color: recordHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                W-L
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((row, index) => (
              //console.log("ROW", row),
                  <TableRow
                    key={row.userId}
                    sx={{
                      backgroundColor: row.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                    }}
                  >
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '57px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px'}}>
                    {leaderboardInterval === 'Season Standings' 
                      ? getUserRank(row, partyId, rankForUsersWithoutRank)
                      : row?.rank ? row?.rank : rankForUsersWithoutRank}
                    </TableCell>
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '162px', fontSize: isMobile ? '12px' : '16px' }}>
                      <span
                        style={{ textDecoration: 'underline', fontWeight: 700 }}
                      >                      
                          {row?.username}
                      </span>
                      {hasWeekStarted && leaderboardInterval !== 'Season Standings' && (
                        <div style={{ fontSize: isMobile ? '12px' : '16px'}}>{thisIntervalsTiebreaker(row)}</div>
                      )}
                      {timeToRevealSeasonTiebreaker ? (
                        !hasWeekEnded ? (
                          <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                            {thisIntervalsTiebreaker(row)}
                          </div>
                        ) : (
                          <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                            Tiebreaker Margin: {(row?.finalMargin && row?.finalMargin !== 5000) ? row?.finalMargin : 'N/A'}
                          </div>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {row?.totalPoints ?? 0}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '47px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {`${row?.totalWins ?? 0}-${row?.totalLosses ?? 0}`}
                    </TableCell>

                  </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ maxWidth: '750px', margin: '0 auto' }}>
              <TablePagination
                rowsPerPageOptions={[50, 100]}
                component="div"
                count={sortedData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={Math.min(page, Math.max(0, Math.ceil((sortedData?.length || 0) / rowsPerPage) - 1))}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Rows"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiTablePagination-toolbar': {
                    color: '#002129',
                  },
                  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                    color: '#002129',
                  }
                }}
              />
            </Box>
          </>
      )}
    </Box>
      
    {tiebreakInfoOpen && (
      <TiebreakerModal
        tiebreakInfoOpen={tiebreakInfoOpen}
        setTiebreakInfoOpen={setTiebreakInfoOpen}
        tiebreakerInfo={tiebreakerInfo}
        handleRulesClick={handleRulesClick}
      />  
    )}

  </>

  );
};

export default QuickPickLeaderboard;