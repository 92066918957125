import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const GameLogoDisplay = ({ 
    doesUserBelong, 
    logo, 
    gameName, 
    gameLogoDisplayInfo, 
    setJoinOpen, 
    hasJoinDeadlinePassed,
    isHostGame,
    affiliatedGamesInfo, 
    gameAddress,
    isTeamGame,
}) => {
    //console.log("Is Host Game: ", isHostGame, "Affiliated Games Info: ", affiliatedGamesInfo);
   
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };
    
    const { text, bottomText, images } = gameLogoDisplayInfo;

    const handleJoinClick = () => {
        setJoinOpen(true);
    }

    const handleGameClick = (id) => {
        window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(id)}/HOME/HOME`;
    }

    return (
        <Box
            sx={{
                backgroundColor: "#F2F2F2",
                padding: '8px 0px 12px 0px',
            }}
        >
            <Box>
                {text?.map((textItem, index) => (  
                    <Typography
                        key={index}
                        sx={{
                            fontWeight: '700',
                            fontSize: index === 0 ? (isMobile ? '14px' : '20px') : (isMobile ? '16px' : '24px'),
                            fontStyle: textItem.italic ? 'italic' : 'normal',
                        }}
                    >
                        {textItem.line}
                    </Typography>
                ))}
            </Box>

            {isHostGame && affiliatedGamesInfo && (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        rowGap: '16px',
                        columnGap: '36px',
                        margin: '16px auto',
                        width: 'fit-content',
                        maxWidth: '100%',
                        ...(isMobile && {
                            width: '236px', // Exactly fits 2 100px images with 24px gap (100px + 24px + 100px)
                        })
                    }}
                >
                    {affiliatedGamesInfo.map((game, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: isMobile ? '100px' : '150px',
                                height: isMobile ? '100px' : '150px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={game.circleLogo}
                                alt={game.name}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    // cursor: 'pointer',
                                }}
                                // onClick={() => handleGameClick(game.id)}
                            />
                        </Box>
                    ))}
                </Box>
            )}

            {!isHostGame && !isTeamGame && !images && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: isMobile ? '235px' : '400px',
                        margin: '0 auto',
                        marginBottom: '12px',
                        marginTop: '8px',
                    }}
                >
                    <img src={logo} alt={gameName} />
                </Box>
            )}

            {images && (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: images.length === 1 ? 'center' : 'space-between', // Center if one, space-between if two
                    gap: images.length > 1 ? '12px' : 0, // Add a gap if more than one image
                    width: isMobile ? '80%' : '500px',
                    maxWidth: isMobile ? '400px' : '500px',
                    margin: '0 auto',
                    marginBottom: '12px',
                    marginTop: '8px',
                }}
            >
                {images?.map((image, index) => (
                <img 
                    key={index} 
                    src={image?.image} 
                    alt={image?.alt || `image-${index}`} 
                    style={{
                    width: 'auto',
                    height: image?.height, // Maintain aspect ratio
                    }} 
                />
                ))}
            </Box>
            )}

            {bottomText && !doesUserBelong && (
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '12px',
                        marginTop: '16px',
                        fontWeight: '700',
                        fontSize: isMobile ? '12px' : '14px',
                    }}
                >
                    {bottomText}
                </Typography>
            )}

            {!hasJoinDeadlinePassed && !doesUserBelong && (
                <Button
                    variant="contained"
                    className="game-home-page-button join-pool-button"
                    onClick={() => handleJoinClick()}
                >
                    JOIN POOL
                </Button>
            )}
        </Box>
    );
}

export default GameLogoDisplay;