import React from 'react';
import { Typography, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SponsorLocations = ({ location, address1, address2, phoneNumber, hours, extraInfo, locationCount }) => {
    const [locationOpen, setLocationOpen] = React.useState(locationCount === 1);

    const toggleLocation = () => {
        if (locationCount > 1) {
            setLocationOpen(prevState => !prevState);
        }
    };

    return (
        <Box className="sponsor-tab-locations-container">
            <Box className="sponsor-tab-header" onClick={toggleLocation}>
                <Typography className='sponsor-tab-location-text' sx={{ cursor: locationCount === 1 ? 'default' : 'pointer'}}>
                    {location}
                </Typography>
                {locationCount > 1 && (
                    <KeyboardArrowDownIcon 
                        className={`sponsor-tab-icon ${locationOpen ? 'open' : ''}`}
                    />
                )}
            </Box>

            {locationOpen && (
                <>
                    <Typography className='sponsor-tab-main-text-first'>
                        {address1}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {address2}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {phoneNumber}
                    </Typography>

                    <Box className="sponsor-tab-hours">
                        {hours.map((time, index) => (
                            <Box key={index} className="sponsor-tab-hours-block">
                                {time?.header && (
                                    <Typography className='sponsor-tab-main-text-underline'>
                                        {time?.header}
                                    </Typography>
                                )}
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.days}
                                </Typography>
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.hours}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Typography className='sponsor-tab-main-text'>
                        {extraInfo}
                    </Typography>
                </>
            )}
        </Box>
    );
};

const Locations = ({ locations }) => {
    return (
        <>
            <Typography className="sponsor-tab-header-text">
                Location{locations?.length > 1 ? 's' : ''}
            </Typography>

            {locations.map((info, index) => (
                <SponsorLocations
                    key={index}
                    location={info?.location}
                    address1={info?.address1}
                    address2={info?.address2}
                    phoneNumber={info?.phoneNumber}
                    hours={info?.hours}
                    extraInfo={info?.extraInfo}
                    locationCount={locations.length}
                />
            ))}
        </>
    );
};

export default Locations;