import React, { useState } from "react";
import { Menu, MenuItem, Button, createTheme, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: "var(--primary-color)",
            color: "white",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "var(--color-white)",
          textTransform: "none",
          fontSize: '1rem',
          "&:hover": {
            color: "var(--color-yellow)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.MuiListItem-root": {
            "&:hover": {
              backgroundColor: "transparent", // Remove default hover background
            },
          },
        },
      },
    },
  },
});

const uriEncoded = (link) => encodeURIComponent(link);

export default function MyPools(props) {
  const { games } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate(); // Access navigate function from useNavigate

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path) => {
    navigate(path); // Use navigate function to go to the specified path
    handleClose(); // Close the menu after navigation if needed
  };

  const navigateWithReload = (path) => {
    window.location.href = path;
    handleClose();
  };

  if (!games?.length) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="hidden md:block">
        <Button
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="text"
          sx={{
            whiteSpace: 'nowrap', // Prevents text from wrapping
            overflow: 'hidden', // Ensures overflow content is hidden
            display: 'inline-block' // Ensures the button remains inline
          }}
        >
          My Pools
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => navigateTo("/my-pools")}>
            <Link to="/my-pools">My Pools Page</Link>
          </MenuItem>
          {games.map((game) => {
            const gameActualPath = game?.gameType === "BirdiePool"
              ? `/birdie-pool/${uriEncoded(game.gameID)}/SCORE_CARD/${uriEncoded(game.headerDisplayName)}`
              : `/${uriEncoded(game.gameAddress)}/${uriEncoded(game.gameID)}/HOME/HOME`;

            return (
              <MenuItem key={game.gameID} onClick={() => navigateWithReload(gameActualPath)}>
                <Link 
                  to={gameActualPath}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default <Link> behavior
                    navigateWithReload(gameActualPath); // Custom navigation logic
                  }}  
                >
                  {game.pool?.gameType === "SuperPickem" ? "NBA Super Pick 'Em" : `${game.gameName}`}
                </Link>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
      <div className="block md:hidden px-4 text-white font-semibold">
          <div className="font-bold" style={{ whiteSpace: 'nowrap' }}>My Pools</div>
          {games.slice(0, 5).map((game) => {
            const gameActualPath = game?.gameType === "BirdiePool"
              ? `/birdie-pool/${uriEncoded(game.gameID)}/SCORE_CARD/${uriEncoded(game.headerDisplayName)}`
              : `/${uriEncoded(game.gameAddress)}/${uriEncoded(game.gameID)}/HOME/HOME`;

            return (
              <div
                key={game.gameID}
                onClick={() => navigateWithReload(gameActualPath)}
                className="pl-5 py-1 block cursor-pointer"
              >
                {game.pool?.gameType === "SuperPickem" ? "NBA Super Pick 'Em" : `${game.gameName}`}
              </div>
            );
          })}
          {games.length > 5 && (
            <div
              onClick={() => navigateTo("/my-pools")}
              className="pl-5 py-1 block cursor-pointer"
            >
              More...
            </div>
          )}
      </div>

    </ThemeProvider>
  );
}
