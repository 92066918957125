import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import StaticPoolHeaderBar from '../../PoolHeaderBar/StaticPoolHeaderBar';
import SponsorPoolsCard from './SponsorPoolsCard';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetSharedGameIds } from '../../../hooks/pools/useGetSharedGameIds';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const SponsoredPoolsPage = () => {
    const gameId = "18bafa5c-812b-408d-9c64-c2ef800aaf84";
    const { mutate: fetchSharedGameIds, data: sharedGameData } = useGetSharedGameIds();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [page, setPage] = useState('All');

    // Use Effect to get shared game ids
    useEffect(() => {
        if (gameId) {
            fetchSharedGameIds({ gameID: gameId});
        }
    }, [gameId]);
    console.log("Shared Game Ids: ", sharedGameData);    

    const poolData = sharedGameData?.filter(game => game.sponsored);

    // Get unique types from poolData
    const types = ['All', ...new Set(poolData?.map(pool => pool.type))];

    const filteredPools = poolData?.filter(pool => 
        page === 'All' ? true : pool.type === page
    );

    if (!sharedGameData) {
        return <LoadingScreen />;
    }

    return (
        <>
            <StaticPoolHeaderBar
                navigationText={"Pool Hall"}
                headerText={"Signature Pools"}
                linkForNavigation={"/pool-hall"}
            />

            {types?.length > 2 && (
                <Box sx={{ marginBottom: '32px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        maxWidth: isMobile ? '300px' : '350px',
                        margin: '15px auto'
                    }}>
                        {types?.map((tab) => (
                            <span
                                key={tab}
                                style={{
                                    display: 'block',
                                    padding: '12px 8px',
                                    borderRadius: '6px',
                                    backgroundColor: page === tab ? '#E8E8E8' : null,
                                    color: '#002129',
                                    fontSize: isMobile ? '12px' : '18px',
                                    fontWeight: '700',
                                    cursor: 'pointer',
                                    marginRight: isMobile ? '0px' : '70px',
                                    whiteSpace: 'nowrap'
                                }}
                                onClick={() => setPage(tab)}
                            >
                                {tab}
                            </span>
                        ))}
                    </div>
                </Box>
            )}

            {types?.length <= 2 && (
                <Box sx={{ height: '32px' }} />
            )}

            {filteredPools?.map((pool, index) => (
                <React.Fragment key={index}>
                    <SponsorPoolsCard {...pool} />
                    <Box sx={{ height: '16px' }} />
                </React.Fragment>
            ))}
            <Box sx={{ height: '32px' }} />
        </>
    );
};

export default SponsoredPoolsPage;