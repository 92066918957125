import React from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';

const SponsorPoolsCard = (props) => {
    const theme = useTheme(); 
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); 
    
    const handleButtonClick = () => {
        window.location.href = props.link;  
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                border: isMobile ? '2px solid #E8E8E8' : '4px solid #E8E8E8',
                borderRadius: '8px',
                width: isMobile ? '350px' : '500px',
                margin: '0 auto',
            }}
        >
            {props.header && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: '700',
                        color: '#002129',
                    }}
                >
                    {props.header}
                </Typography>
            )}

            {props.subHeader && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '700',
                        color: '#002129',
                    }}
                >
                    {props.subHeader}
                </Typography>
            )}

            {props.image && (
                <Box
                    sx={{
                        width: isMobile ? '250px' : '400px',
                        height: 'auto',
                        margin: '16px 0',
                    }}
                >
                    <img
                        src={props.image}
                        alt="pool"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Box>
            )}

            {props.message?.map((message, index) => (
                <Typography
                    key={index}
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '500',
                        color: '#002129',
                        textAlign: 'center',
                    }}
                >
                    {message}
                </Typography>
            ))}

            {props.prizes && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        color: '#002129',
                        textAlign: 'center',
                        margin: '16px 0',
                    }}
                >
                    Prizes: 
                    <Box
                        component="span"
                        sx={{
                            fontWeight: '500',
                        }}
                    >
                        {` ${props.prizes}`}
                    </Box>
                </Typography>
            )}

            {props.buttonText && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        sx={{
                            padding: '8px 16px',
                            borderRadius: '4px',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={handleButtonClick}
                    >
                        {props.buttonText}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default SponsorPoolsCard;