import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RemovedFromGameModal = ({ open, gameName }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
      disablePortal={false}
      disableRestoreFocus
      hideBackdrop={false}
      onClose={(event, reason) => {
        // Prevent closing by clicking outside
        if (reason === 'backdropClick') return;
      }}
    >
      <Box sx={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        maxWidth: '350px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 4,
        p: 4,
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Removed
        </Typography>
        
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          You have been removed from {gameName}.
          Please reach out to a game administrator with any questions.
        </Typography>

        <Button 
          variant="contained"
          color="primary"
          onClick={() => navigate('/pool-hall')}
          sx={{ mt: 2 }}
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default RemovedFromGameModal;