import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams, useNavigate } from "react-router-dom";
import CommissionerInvites from "./CommissionerInvites";
import ManageMembers from "./ManageMembers";
import MyPartyEmailMembers from "../MyParty/MyPartyEmailMembers";

const CommissionerPage = ({
    gameData,
    myUserId,
    gameAdministrator,
    gameCommissioners,
    leaderboardInfo,
    mainGameId,
    gameName,
    isGameOver,
    doIHaveCommissionerAccess,
    gameAddress,
}) => {

    const [page, setPage] = useState('Send Invites');
    const { gameId, component, pageState, optionalState } = useParams();
 

    useEffect(() => {
        if (isGameOver && page === 'Send Invites') {
        setPage('Manage Members');
        }
    }, [isGameOver]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    const navigate = useNavigate();

    useEffect(() => {
    if (optionalState) {
        if (optionalState === 'INVITE') {
        setPage('Send Invites');
        } else if (optionalState === 'MANAGE') {
        setPage('Manage Members');
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('MANAGE')}`);
        } else if (optionalState === 'EMAIL') {
        setPage('Email Members');
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('EMAIL')}`);
        }
    }}, [optionalState]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Typography
                variant="h3"
                style={{
                    color: "white",
                    background: "#002F3A",
                    fontWeight: '700',
                    margin: '0 auto',
                    padding: '5px 0',
                    fontSize: isMobile ? '12px' : '14px'
                }}
            >
                {`Manage ${gameName}`}
            </Typography>

            <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '675px', margin:'15px auto'}}>
              {!isGameOver && (
                <span
                  style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Send Invites' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                  onClick={() => {
                    setPage('Invite Members');
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('INVITE')}`);
                  }}
                >
                  Send Invites
                </span>
              )}
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Manage Members' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                  setPage('Manage Members');
                  navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('MANAGE')}`);
                }}              
              >
                Manage Members
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Email Members' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                  setPage('Email Members');
                  navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('EMAIL')}`);
                }}              
              >
                Email Members
              </span>
            </div>

            {page === 'Send Invites' && (
                <CommissionerInvites
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    leaderboardInfo={leaderboardInfo}
                    mainGameId={mainGameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    doIHaveCommissionerAccess={doIHaveCommissionerAccess}
                    gameAddress={gameAddress}
                />
            )}

            {page === 'Manage Members' && (
                <ManageMembers
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrator={gameAdministrator}
                    leaderboardInfo={leaderboardInfo}
                    mainGameId={mainGameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    doIHaveCommissionerAccess={doIHaveCommissionerAccess}
                    gameAddress={gameAddress}
                />
            )}

            {page === 'Email Members' && (
                <MyPartyEmailMembers
                    leaderboardData={leaderboardInfo}
                    isGameOver={isGameOver}
                    gameName={gameName}
                />
            )}

        </div>
    );
}

export default CommissionerPage;