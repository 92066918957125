import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../../BirdiePool/hooks/index";
import { useParams, useNavigate } from "react-router-dom";
import { useGetGameMembers } from "../../../../hooks/general/useGetGameMembers";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../../redux/actions/loginImport";
import useNavigateToTop from "../../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../../redux/actions/loginImport";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import { useNFLScoresBySeason, useNFLTeams, useNFLCurrentWeek, useQuickPickNFL, useNFLStandings, useNFLPostseasonSchedule } from "../../../../hooks/nfl/index";
import { useGetPoolLeaderboard } from "../../../../hooks/pools/useGetPoolLeaderboard";
import { useGetSharedGameIds } from "../../../../hooks/pools/useGetSharedGameIds";
import { DateTime } from "luxon";
import { useGetGameReferrals } from "../../../../hooks/pools/useGetGameReferrals";


const QuickPickNFLContext = createContext();

export const useQuickPickNFLContext = () => {
    const context = useContext(QuickPickNFLContext);
    if (!context) {
        throw new Error("useQuickPickNFLContext must be used within a QuickPickNFLProvider");
    }
    return context;
};

const QuickPickNFLProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log("myUserId: ", myUserId);

    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchNFLScoresBySeason, data: nflScoresBySeasonData, isLoading: nflScoresBySeasonLoading, isError: nflScoresBySeasonError, error: nflScoresBySeasonErrorData } = useNFLScoresBySeason();
    const { mutate: fetchNFLTeams, data: nflTeams, isLoading: nflTeamsLoading, isError: nflTeamsError, error: nflTeamsErrorData } = useNFLTeams();
    const { mutate: fetchNFLCurrentWeek, data: nflCurrentWeek, isLoading: nflCurrentWeekLoading, isError: nflCurrentWeekError, error: nflCurrentWeekErrorData } = useNFLCurrentWeek();
    const { mutate: fetchQuickPickNFL, data: quickPickNFLData, isLoading: quickPickNFLLoading, isError: quickPickNFLError, error: quickPickNFLErrorData } = useQuickPickNFL();
    const { mutate: fetchNFLStandings, data: nflStandings, isLoading: nflStandingsLoading, isError: nflStandingsError, error: nflStandingsErrorData } = useNFLStandings();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: fetchSharedGameIds, data: sharedGameIdsData, isLoading: sharedGameIdsLoading, isError: sharedGameIdsError, error: sharedGameIdsErrorData } = useGetSharedGameIds();
    const { mutate: createSubgroup, data: subgroupData, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();
    const { mutate: fetchGameMembers, data: gameMembersData, isLoading: gameMembersLoading, isError: gameMembersError, error: gameMembersErrorData } = useGetGameMembers();
    const { mutate: fetchGameReferrals, data: gameReferrals } = useGetGameReferrals();
    const { mutate: fetchNFLPostseasonSchedule, data: nflPostseasonScheduleData, isLoading: nflPostseasonScheduleLoading, isError: nflPostseasonScheduleError, error: nflPostseasonScheduleErrorData } = useNFLPostseasonSchedule();

    const { gameId, component, pageState } = useParams();

    const gameType = "QuickPick";
    const league = "NFL";

    const dispatch = useDispatch();
    const navigateToTop = useNavigateToTop();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    console.log("LOADING: ", loading);  

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    //console.log("userData: ", userData);

    // Fetch game data second
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    //console.log("gameData: ", gameData);
    const season = gameData?.season;
    const includePostseason = gameData?.gameParameters[0]?.includePostseason;
    const includeRegularSeason = gameData?.gameParameters[0]?.includeRegularSeason;
    const dontUseReferrals = gameData?.gameParameters[0]?.dontUseReferrals;
    const isMainGame = gameData?.gameParameters[0]?.mainGame;
    const mainGameInfo = gameData?.gameParameters[0]?.mainGameInfo;
    console.log("Is Main Game: ", isMainGame, "Main Game Info: ", mainGameInfo);

    console.log("Dont Use Referrals: ", dontUseReferrals);
    // Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
            if (!dontUseReferrals) {
                console.log("Getting Referrals")
                fetchGameReferrals({ gameID: gameId });
            } else {
                console.log("Not Getting Referrals")
            }
        }
    }, [fetchLeaderboardData, gameId, gameData, fetchGameReferrals, dontUseReferrals]);

    //console.log("LEADERBOARD", leaderboardData);

    // Error Handling for Leaderboard Data, will LOG OUT on expired token, otherwise will navigate to 404
    // Error handling for leaderboard fetching
  useEffect(() => {
    if (leaderboardError) {
        console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
        if (leaderboardErrorData.response?.status === 401) {
            dispatch(userLogoutAction({
                logoutType: 'token-expired'
            }));
        } else {
            navigateToTop('/404/pool-hall');
        }
    }

    if (leaderboardData) {
        //console.log("Leaderboard data received:", leaderboardData);
    }
  }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

  // Use Effect to get Shared Game Ids
    useEffect(() => {
        if (gameData) {
            const isMainGame = gameData?.gameParameters[0]?.mainGame;
            const mainGameInfo = gameData?.gameParameters[0]?.mainGameInfo;
            if (isMainGame) {
                console.log("IS Main Game. ID: ", gameId);
                fetchSharedGameIds({ gameID: gameId });
            } else if (mainGameInfo) {
                const mainGameId = mainGameInfo[0]?.id;
                console.log("Main Game Id: ", mainGameId);
                fetchSharedGameIds({ gameID: mainGameId });
            }
        }
    }, [gameData, isMainGame, mainGameInfo, fetchSharedGameIds, gameId]);
    console.log("Shared Game Ids: ", sharedGameIdsData);

    // Extract just the game IDs from userData.Games
    const userGameIds = userData?.Games?.map(game => game.gameID) || [];
    console.log("User Game Ids: ", userGameIds);


    // Filter sharedGameIdsData to keep only matching entries
    const matchedGames = sharedGameIdsData?.filter(game => userGameIds?.includes(game.gameId) && game.gameId !== gameId);

    console.log("Matched Games: ", matchedGames);



    const [hasFetchedData, setHasFetchedData] = useState(false);
    console.log("LOADING Data Fetched????? ", hasFetchedData);

    // Use Effect to fetch NFL data
    useEffect(() => {
        //console.log("Running Use Effect");
        if (!hasFetchedData && season) {
                //console.log("Fetching NFL Data");
                fetchNFLTeams();
                fetchNFLCurrentWeek();
                fetchQuickPickNFL(season);
                fetchNFLStandings();
                if (includeRegularSeason) {
                    fetchNFLScoresBySeason();
                }
                if (includePostseason) {
                    console.log("Fetching Postseason Data");
                    // Add Postseason Fetches Here
                    fetchNFLPostseasonSchedule(season);
                }

            setHasFetchedData(true);
        }
    }, [hasFetchedData, season, fetchNFLTeams, fetchNFLScoresBySeason, fetchNFLCurrentWeek, fetchQuickPickNFL, fetchNFLStandings, fetchNFLPostseasonSchedule, includePostseason, includeRegularSeason]);
    //console.log("nflTeams: ", nflTeams, "nflScoresBySeason: ", nflScoresBySeasonData, "nflCurrentWeek: ", nflCurrentWeek, "quickPickNFLData: ", quickPickNFLData);

    // Set loading to false when all data is fetched
    useEffect(() => {
        if (gameData  && leaderboardData && nflTeams && (nflScoresBySeasonData || nflPostseasonScheduleData) && nflCurrentWeek && quickPickNFLData) {
            setLoading(false);
        }
    }, [gameData, leaderboardData, nflTeams, nflScoresBySeasonData, nflPostseasonScheduleData, nflCurrentWeek, quickPickNFLData]);

    console.log("Game Data: ", gameData, "Leaderboard Data: ", leaderboardData, "NFL Teams: ", nflTeams, "NFL Scores By Season: ", nflScoresBySeasonData, "NFLPost Season", nflPostseasonScheduleData, "NFL Current Week: ", nflCurrentWeek, "Quick Pick NFL Data: ", quickPickNFLData);
    const activeGameMembers = gameData?.clubMembers || [];
    const filteredLeaderboardData = leaderboardData?.filter((leaderboardUser) => activeGameMembers?.includes(leaderboardUser.userId));
    console.log("ActiveGameMembers: ", activeGameMembers, "Filtered Leaderboard Data: ", filteredLeaderboardData);

    const pinRequired = gameData?.password;
    const gameParameters = gameData?.gameParameters[0];
    const { gameAdministrator, gameCommissioners, doIHaveCommissionerAccess } = useMemo(() => ({
        gameCommissioners: gameParameters?.gameCommissioners,
        gameAdministrator: gameParameters?.gameAdministrator,
        doIHaveCommissionerAccess: gameParameters?.gameCommissioners?.includes(myUserId) || gameParameters?.gameAdministrator?.includes(myUserId)
    }), [gameParameters, myUserId]);
    console.log("Game Admin: ", gameAdministrator, "Game Commissioners: ", gameCommissioners, "Do I Have Commissioner Access: ", doIHaveCommissionerAccess);
    //console.log("gameParameters: ", gameParameters);
    const isTiebreakerGame = gameParameters?.tiebreakerGame;
    const tiebreakerGamesInfo = gameParameters?.tiebreakerGamesInfo;
    console.log("Is Tiebreaker Game: ", isTiebreakerGame, "Tiebreaker Games Info: ", tiebreakerGamesInfo);

    let myTiebreakerGames = [];
    if (isTiebreakerGame && tiebreakerGamesInfo?.length > 0) {
        // Filter and find the matching entries from tiebreakerGamesInfo
        const matchingTiebreakerGames = tiebreakerGamesInfo.filter(info =>
            userData?.Games?.some(game => game.gameID === info.id) // Compare info.id with game.gameID
        );

        //If any Matching tiebreakers have subGame : true, add the tiebreakerGamesInfo entry that has host : true
        const subGameTiebreakers = matchingTiebreakerGames?.filter(info => info.subGame);
        console.log("Sub Game Tiebreakers: ", subGameTiebreakers);
        if (subGameTiebreakers?.length > 0) {
            const hostTiebreaker = tiebreakerGamesInfo?.find(info => info.host);
            if (hostTiebreaker) {
                myTiebreakerGames.push(hostTiebreaker);
            }
        }

        // Push the matching entries into myTiebreakerGames
        if (matchingTiebreakerGames?.length > 0) {
            myTiebreakerGames.push(...matchingTiebreakerGames); // Use spread operator to add all matching entries
        }
    }

    console.log("My Tiebreaker Games: ", myTiebreakerGames);

    // Use Effect to get Game Members if myTimebreakerGames is not empty
    const stableFetchGameMembers = useCallback(fetchGameMembers, []); // Stable reference

    useEffect(() => {
        console.log("running Use Effect for Game Members");
        if (myTiebreakerGames.length > 0) {
            const gameIDs = myTiebreakerGames.map(game => game.id);
            stableFetchGameMembers({ gameIDs });
        }
    }, [JSON.stringify(myTiebreakerGames), stableFetchGameMembers]); // Dependencies are stable and compared properly

    console.log("Game Members Data: ", gameMembersData);

    // const countdownDeadline = gameParameters?.countdownDeadline;
    // const countdownTimer = gameParameters?.countdownTimer;
    // const countdownMessage = gameParameters?.countdownMessage;
    const showMembersPage = gameParameters?.showMembersPage;
    const picksPerInterval = gameParameters?.picksPerInterval;
    const usingTiebreaker = gameParameters?.usingTiebreaker;
    const tiebreakerInfo = gameParameters?.tiebreakerInfo;
    const intervalType = gameParameters?.intervalType;
    const props = gameParameters?.props;
    const propIdsToUse = gameParameters?.propIdsToUse;
    const howManyWinners = gameParameters?.howManyWinners;
    //console.log("PropIdsToUse: ", propIdsToUse);
    const tiebreakerToUse = props?.tiebreakers;
    const gamePropsToUse = props?.gameProps;
    const selectedTeamPropsToUse = props?.selectedTeamProps;
    const opponentTeamPropsToUse = props?.opponentTeamProps;
    //console.log("Props: ", props, "Game Props: ", gamePropsToUse, "Selected Team Props: ", selectedTeamPropsToUse, "Opponent Team Props: ", opponentTeamPropsToUse, "Tiebreaker to Use", tiebreakerToUse);
    const firstInterval = gameParameters?.firstInterval;
    const lastInterval = gameParameters?.lastInterval;
    const teamsToInclude = gameParameters?.teamsToInclude; // Leave this as undefined or null for no filtering
    const gamesToInclude = gameParameters?.gamesToInclude; // Leave this as undefined or null for no filtering
    const countdownMessageFromParams = gameParameters?.countdownMessage;
    const countdownText = gameParameters?.countdownText;
    const countdownBackground = gameParameters?.countdownBackground;
    const useColorsForHeader = gameParameters?.useColorsForHeader;
    const daysToInclude = gameParameters?.daysToInclude;  // Leave this as undefined or null for no filtering, format is ["Sunday", "Saturday"]
    const filterTime = gameParameters?.filterTime;  // Leave this as undefined or null for no filtering, format is "HH:MM" (18:00 for 6:00 PM)
    const filterDate = gameParameters?.filterDate; // Leave this as undefined or null for no filtering, format is "YYYY-MM-DD" (2024-12-25 for December 25, 2024)
    const homeOnly = gameParameters?.homeOnly;  // If true, only include games where the home team is in teamsToInclude
    const awayOnly = gameParameters?.awayOnly;  // If true, only include games where the away team is in teamsToInclude
    const showConsenus = gameParameters?.showConsenus; // If true, show the consensus pick for each game
    const customBackgroundColor = gameParameters?.customBackgroundColor || null;
    const customTextColor = gameParameters?.customTextColor || null;
    const customBorderColor = gameParameters?.customBorderColor || null;
    const myPicksMobileImage = gameParameters?.myPicksMobileImage;
    const myPicksDesktopImage = gameParameters?.myPicksDesktopImage;
    const isGameOver = gameParameters?.isGameOver;

    const joinModalText = gameData?.joinModalText;
    //console.log("Join Modal Text: ", joinModalText);

    const currentInterval = includeRegularSeason ? nflCurrentWeek : 1;
    // ADD IN POST SEASON WEEK ONCE THERES A FETCH AND ITS CONNECTED!!!!!!!!!!!!!!!!!!
    //console.log("Current Interval: ", currentInterval);

    let byeWeek = null;
    if (Array.isArray(teamsToInclude) && teamsToInclude.length > 0) {
        const firstTeam = teamsToInclude[0];
        byeWeek = nflTeams?.find(team => team.GlobalTeamID === firstTeam)?.ByeWeek;
    }

    //console.log("Bye Week: ", byeWeek);

    const [pickState, setPickState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(null);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState("");
    const [partyId, setPartyId] = useState(gameId);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    //console.log("Interval: ", interval, "Pick State", pickState);

    useEffect(() => {
        //console.log("Running Use Effect!!!!!");
        if (userData && gameId) {
          const thisGame = userData?.Games?.find(game => game.gameID === gameId);
          if (thisGame && thisGame?.pool?.picks) {
            //console.log("Running Use Effect 2!!!!!", thisGame?.pool?.picks);
            setPickState(thisGame?.pool?.picks);
          }
        }
      }, [userData, gameId]);

    // useEffect(() => {
    //     //console.log("Running Context Interval Use Effect")
    //     if (currentInterval < firstInterval) {
    //         setInterval(firstInterval);
    //     } else if (currentInterval > lastInterval) {
    //         setInterval(lastInterval);
    //     } else {
    //         setInterval(currentInterval);
    //     }
    // }, [currentInterval, firstInterval, lastInterval, interval]);
    //console.log("Interval: ", interval, "Last Interval: ", lastInterval, "First Interval: ", firstInterval, "Current Interval: ", currentInterval);

    const userSubgroups = userData?.subgroups || [];
   
    //console.log("teamsToInclude: ", teamsToInclude, "firstInterval: ", firstInterval, "daysToInclude:", daysToInclude, "filterTime:", filterTime, "filterDate:", filterDate);

    let gameSchedule = includeRegularSeason ? nflScoresBySeasonData : nflPostseasonScheduleData;
    console.log("nflScoresBySeason: BEFORE Filter", gameSchedule);

    if (firstInterval && (nflScoresBySeasonData || nflPostseasonScheduleData) && gameParameters) {
        gameSchedule = gameSchedule?.filter(game => {
            // If neither filter exists, keep all games
            if (!teamsToInclude && !gamesToInclude) {
                return true;
            }
            
            // If we have teams to include, check if either home or away team matches
            const teamMatch = teamsToInclude?.includes(game.GlobalHomeTeamID) || 
                             teamsToInclude?.includes(game.GlobalAwayTeamID);
    
            // If we have specific games to include, check if the game ID matches
            const gameMatch = gamesToInclude?.includes(game.GlobalGameID);
    
            // Return true if either condition matches (when their respective filters exist)
            return teamMatch || gameMatch;
        });
    }
    
    console.log("Teams to Include: ", teamsToInclude);
    console.log("GameIds to Include: ", gamesToInclude);
    console.log("nflScoresBySeason: AFTER Filter", gameSchedule);

    const nearestGame = gameSchedule
    ?.sort((a, b) => new Date(a.Day) - new Date(b.Day)) // Sort by date (ascending)
    ?.find((game) => {
        const nowInNewYork = DateTime.now().setZone('America/New_York').startOf('day'); // Get today's date at 00:00
        const gameDate = DateTime.fromISO(game.Day, { zone: 'America/New_York'}).startOf('day'); // Game date at 00:00
        return gameDate >= nowInNewYork; // Find the first game today or in the future
    });

    console.log("Nearest Game: ", nearestGame);
    const nearestGameWeek = nearestGame?.Week;

    const oneWeekEvent = firstInterval === lastInterval;

    let countdownTimer = true;
    if (!nearestGame) {
        countdownTimer = false;
    }
    let countdownMessage = `Week ${nearestGameWeek} picks lock in:`;
    if (oneWeekEvent && countdownMessageFromParams) {
        countdownMessage = countdownMessageFromParams;
    }
    
    const countdownDeadline = nearestGame?.DateTime;

    let quickPickNFLGames = quickPickNFLData;

    const currentPropIds = propIdsToUse?.filter((entry) => entry.interval === interval)[0]?.propIdsToUse || [];

    // Filter quickPickNFLGames to include only games that are also in gameSchedule
    quickPickNFLGames = quickPickNFLGames?.filter((quickGame) => {
        // First, find matching game based on GlobalGameID
        const matchingGame = gameSchedule?.find(
        (seasonGame) => seasonGame.GlobalGameID === quickGame.GlobalGameID
        );

        return matchingGame; // Include the game if there's a match
    });

      console.log("quickPickNFLGames: AFTER Filter 2nd ", quickPickNFLGames);


    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime, { zone: 'America/New_York' });
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];
    const referralPrizes = gameData?.referralPrizes;



    // Find the game in the user data
    let doesUserBelong = false;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    }
    const removedUsers = gameData?.gameParameters[0]?.removedUsers || [];
    console.log("Removed Users: ", removedUsers);
    const amIRemoved = removedUsers?.some(user => user.memberId === myUserId);
    console.log("Am I Removed: ", amIRemoved);

    let textOptIn = gameData?.textOptIn;

    const amIOptedInToTexts = textOptIn?.includes(myUserId);

    //console.log("doesUserBelong: ", doesUserBelong);
    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards?.find(reward => reward.interval === 0);
    //console.log("Rewards: ", rewards, "Join Reward: ", joinReward);
    //console.log("User Picks from DB: ", userPicksFromDB);

    // Utility function to check if defaultPartyIdInDB is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        return subgroups.some(subgroup => subgroup.subGroupID === partyId);
    };

    // Get the default party ID with the additional check
    let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
    ? thisGame.pool.defaultParty[0]
    : gameId;

    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB);


    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = gameName ? gameName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : gameName ? gameName : "Pool Party";
    }
    //console.log("Default Party Name", defaultPartyNameInDB);

    // Use Effect to set default Party Id and name
    useEffect(() => {
        console.log("Running Use Effect for Setting Default Party Id and Name");
        setDefaultPartyId(defaultPartyIdInDB);
        setDefaultPartyName(defaultPartyNameInDB);
    }, [defaultPartyIdInDB, defaultPartyNameInDB]);

    //Use Effect for setting the week and party state based on the component and page state
    useEffect(() => {
        //console.log("Running Use Effect for Setting Week and Party State");
        if (gameData && component) {
            let newInterval = currentInterval;

            if (component === "MY_PICKS") {
                if (pageState !== "CURRENT") {
                    const intervalNumber = parseInt(pageState, 10);

                    if (isNaN(intervalNumber) || intervalNumber < firstInterval) {
                        newInterval = firstInterval;
                    } else if (intervalNumber > lastInterval) {
                        newInterval = lastInterval;
                    } else {
                        newInterval = intervalNumber;
                    }
                } else if (firstInterval > currentInterval) {
                    newInterval = firstInterval;
                } else if (lastInterval < currentInterval) {
                    newInterval = lastInterval;
                } else {
                    newInterval = currentInterval;
                }
            }

            if (newInterval !== interval) {
                setInterval(newInterval);
            }

            if (component === "LEADERBOARD" || component === "POOL_PICKS") {
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups?.find(
                        (subgroup) => subgroup.subGroupID === pageState
                    );
                    const pageStateInMyTiebreakerGames = myTiebreakerGames?.find(
                        (game) => game.id === pageState
                    );
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                    } else if (pageStateInMyTiebreakerGames) {
                        setPartyId(pageState);
                        setPartyState(pageStateInMyTiebreakerGames.name);
                    } else {
                        setPartyId(gameId);
                        setPartyState(gameName ? gameName : "Pool Party");
                        navigate(
                            `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`
                        );
                    }
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(
                        `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded("MY_PARTY")}/${uriEncoded(
                            lastSubgroupId
                        )}`
                    );
                } else {
                    const subgroupName = userSubgroups?.find(
                        (subgroup) => subgroup.subGroupID === pageState
                    )?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [
        userData,
        gameData,
        component,
        pageState,
        currentInterval,
        firstInterval,
        lastInterval,
        interval,
        gameId,
        userSubgroups,
        defaultPartyId,
        defaultPartyName,
        lastSubgroupId,
        lastSubgroupName,
        gameAddress,
        navigate,
    ]);



    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };



    console.log("Is Game Over?", isGameOver);

    const nowInNewYork = DateTime.now().setZone('America/New_York');

    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;
    //console.log("Has Join Deadline Passed? CONTEXT", hasJoinDeadlinePassed, "Now In NY", nowInNewYork, "Last Day to Join", lastDayToJoinDateTime);

    const gatherGameInfo = (gameId, teams, schedule, standings) => {
        const gameInfo = schedule?.find(game => game.GlobalGameID === gameId);
        const homeTeamId = gameInfo?.GlobalHomeTeamID;
        const awayTeamId = gameInfo?.GlobalAwayTeamID;
        const homeLogo = teams?.find(team => team.GlobalTeamID === homeTeamId)?.WikipediaLogoURL;
        const awayLogo = teams?.find(team => team.GlobalTeamID === awayTeamId)?.WikipediaLogoURL;
        const homeName = teams?.find(team => team.GlobalTeamID === homeTeamId)?.Key;
        const awayName = teams?.find(team => team.GlobalTeamID === awayTeamId)?.Key;
        const homeWins = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Wins || 0;
        const homeLosses = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Losses || 0;
        const homeTies = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Ties || 0;
        const homeRecord = homeTies > 0 ? `${homeWins}-${homeLosses}-${homeTies}` : `${homeWins}-${homeLosses}`;
        const awayWins = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Wins || 0;
        const awayLosses = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Losses || 0;
        const awayTies = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Ties || 0;
        const awayRecord = awayTies > 0 ? `${awayWins}-${awayLosses}-${awayTies}` : `${awayWins}-${awayLosses}`;
        const homeScore = gameInfo?.HomeScore;
        const awayScore = gameInfo?.AwayScore;
        const gameTime = gameInfo?.DateTime;
        const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
        const status = gameInfo?.Status;
        const homeSpread = gameInfo?.PointSpread;
        const gameLine = homeSpread < 0 ? `(${homeName} ${homeSpread})` : homeSpread === 0 ? `Pick 'Em` : homeSpread > 0 ? `(${awayName} ${homeSpread * -1})` : "No Line";

        return {
            homeLogo,
            awayLogo,
            homeName,
            awayName,
            homeRecord,
            awayRecord,
            homeScore,
            awayScore,
            gameLine,
            gameTime,
            gameDateTime,
            status,
        };
    };

    const gatherDisplayBarInfo = (teamId, teams) => {
        const teamLogo = teams?.find(team => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
        const teamNickname = teams?.find(team => team.GlobalTeamID === teamId)?.Name;

        return {
            teamLogo,
            teamNickname
        };
    }


    const QuickPickNFLContextValues = {
        pickState,
        setPickState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        joinOpen,
        setJoinOpen,
        gameAddress,
        gameData,
        myUserId,
        mainGameId: gameId,
        userSubgroups,
        doesUserBelong,
        currentInterval,
        firstInterval,
        lastInterval,
        gameType,
        isGameOver,
        hasJoinDeadlinePassed,
        gameName,
        myUsername,
        userPicksFromDB,
        nflStandings,
        gameSchedule,
        nflTeams,
        quickPickNFLGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamePropsToUse,
        selectedTeamPropsToUse,
        opponentTeamPropsToUse,
        leaderboardInfo: filteredLeaderboardData,
        userData,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
        byeWeek,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        customBackgroundColor,
        customTextColor,
        customBorderColor,
        myPicksMobileImage,
        myPicksDesktopImage,
        amIOptedInToTexts,
        referralPrizes,
        countdownText,
        countdownBackground,
        isTiebreakerGame,
        tiebreakerGamesInfo,
        myTiebreakerGames,
        gameMembersData,
        gameReferrals,
        useColorsForHeader,
        isMainGame,
        mainGameInfo,
        matchedGames,
        doIHaveCommissionerAccess,
        gameCommissioners,
        gameAdministrator,
        amIRemoved,
        pinRequired,
        showMembersPage,
    };

    // ADD LOADING SCREEN HERE
    if (userLoading || gameLoading || nflScoresBySeasonLoading || nflTeamsLoading || nflCurrentWeekLoading || nflStandingsLoading || quickPickNFLLoading || interval === null || loading || isGameOver === null || gameParameters === null ) {
        return <LoadingScreen />;
    }

    return (
        <QuickPickNFLContext.Provider value={QuickPickNFLContextValues}>
            {children}
        </QuickPickNFLContext.Provider>
    );
};

export default QuickPickNFLProvider;