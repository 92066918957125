import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useAddRemoveUserFromGame = () => {
    return useMutation({
        mutationFn: addRemoveUserFromGameRequest,
        onError: (error) => {
        console.error("Error adding/removing user from game:", error);
        },
        onSuccess: (data) => {
        console.log("User added/removed successfully:", data);
        },
    });
};

const addRemoveUserFromGameRequest = async (gameData) => {
    console.log("Game Data:", gameData);
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage
    console.log(gameData);
    const response = await axios.post(
        `${apiUrl}/user/add-remove-user-from-game`,
        gameData,
        {
        headers: {
            Authorization: authorizationHeader, // Include the authorization header in the request
            "Content-Type": "application/json",
        },
        }
    );
    return response.data;
};