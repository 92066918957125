import React from "react";
import CommissionerPage from "./CommissionerPage";
import ManageMembers from "./ManageMembers";

const MembersPageContainer = ({
    gameData,
    myUserId,
    gameCommissioners,
    gameAdministrator,
    leaderboardInfo,
    mainGameId,
    gameName,
    isGameOver,
    doIHaveCommissionerAccess,
    gameAddress,
}) => {


    return (
        <>
            {doIHaveCommissionerAccess && (
                <CommissionerPage
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrator={gameAdministrator}
                    mainGameId={mainGameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    doIHaveCommissionerAccess={doIHaveCommissionerAccess}
                    leaderboardInfo={leaderboardInfo}
                    gameAddress={gameAddress}
                />
            )}

            {!doIHaveCommissionerAccess && (
                <ManageMembers
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrator={gameAdministrator}
                    mainGameId={mainGameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    doIHaveCommissionerAccess={doIHaveCommissionerAccess}
                    leaderboardInfo={leaderboardInfo}
                    gameAddress={gameAddress}
                />
            )}
        </>
    );
};

export default MembersPageContainer;
