import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';


const TiebreakerGameButtons = ({ 
    myTiebreakerGames,
    isTiebreakerGame,
    gameName,
    mainGame = false,
    matchedGames,
}) => {
console.log("Matched Games in Buttons", matchedGames);
    const mainGameHeader = mainGame ? `The ${myTiebreakerGames?.[0]?.name}` : null;
    const isUserInMainGame = (mainGame && matchedGames?.length > 0) ? matchedGames?.some(game => game?.gameId === myTiebreakerGames?.[0]?.id) : false;
    console.log("Is User In Main Game", isUserInMainGame);

    const tiebreakerGameMessage = "This pool also serves as your tiebreaker for:";
    const mainGameMessage = "Click Below to Switch to The Pool Tiebreaker";
    const subGameMessage = isUserInMainGame ? `Click Below to Switch to The ${myTiebreakerGames?.[0]?.name} Pool` : 
        `Click Below to Join The ${myTiebreakerGames?.[0]?.name} Pool`;

    const tiebreakerDeadline = myTiebreakerGames?.[0]?.tiebreakerDeadline;
    const tiebreakerDeadlineInNewYork = DateTime.fromISO(tiebreakerDeadline, { zone: 'America/New_York' });
    //console.log("Tiebreaker Deadline", tiebreakerDeadline, "In New York", tiebreakerDeadlineInNewYork);

    const formattedDeadline = tiebreakerDeadlineInNewYork?.toFormat("ccc MMM d 'at' h:mma 'ET'");
    //console.log("Formatted Deadline", formattedDeadline);

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleButtonClick = (gameId, gameAddress) => {
        //console.log(`Clicked ${gameId} ${gameAddress}`);
        let url = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`;
        if (isTiebreakerGame) {
            url = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/LEADERBOARD/${uriEncoded(gameId)}`;
        }
        //console.log(`URL: ${url}`);
        window.location.href = url;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                padding: '16px',
                borderBottom: '2px solid #002129',
            }}
        >
            {gameName && !mainGame && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '18px',
                        fontWeight: '700',
                        color: '#002129',
                        textDecoration: 'underline',
                    }}
                >
                    {`The ${gameName} Tiebreaker`}
                </Typography>
            )}

           {mainGame && mainGameHeader && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '18px',
                        fontWeight: '700',
                        color: '#002129',
                        textDecoration: 'underline',
                    }}
                >
                    {mainGameHeader}
                </Typography>
            )}

            <Typography
                sx={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: '700',
                    color: '#002129',
                    marginBottom: '8px',
                }}
            >
                {mainGame ? subGameMessage : isTiebreakerGame ? tiebreakerGameMessage : mainGameMessage}
            </Typography>

            {!isTiebreakerGame && !mainGame && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '18px',
                        fontWeight: '700',
                        color: '#002129',
                        marginBottom: '8px',
                    }}
                >
                    {`Deadline: ${formattedDeadline}`}
                </Typography>
            )}

            {mainGame && !isUserInMainGame && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '18px',
                        fontWeight: '700',
                        color: '#002129',
                        marginBottom: '8px',
                    }}
                >
                    {`FREE to PLAY.  FREE to WIN!`}
                </Typography>
            )}

            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 2,
                }}
            >
                {myTiebreakerGames?.filter(game => !game.host)?.map((game, index) => (
                    <Button 
                        key={index} 
                        variant="contained" 
                        color={game?.color && ['primary', 'secondary', 'error', 'info', 'success', 'warning'].includes(game?.color) 
                        ? game?.color 
                        : 'primary'}
                        sx={{
                            width: '295px',
                            ...(game?.color && !['primary', 'secondary', 'error', 'info', 'success', 'warning'].includes(game?.color) && {
                                backgroundColor: game?.color, // Apply custom background color
                                '&:hover': {
                                    backgroundColor: game?.color, // Hover behavior for custom colors
                                },
                            }),
                        }}
                        onClick={() => handleButtonClick(game.id, game.address)}
                    >
                        {game?.name} Pool
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default TiebreakerGameButtons;
