import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import BracketTeamRowLive from "./BracketTeamRowLive";
import BracketLosingTeam from "./BracketLosingTeam";
import "./SuperPickemBracket.scss";
import { DateTime } from "luxon";
import { hexToRgba } from "../bracketUtils/HexToRgba.js";

const BracketGameLive = ({
    games,
    rounds,
    teams,
    names,
    seeds,
    game,
    round,
    schedule,
    bracketState,
    getBracketInfoPostLock,
    skipRoundOne = false,
    balancedBracket = false,
}) => {

    //console.log("Game: ", game, "Round: ", round, "Games: ", games, "Rounds: ", rounds, "Seeds: ", seeds, "Bracket State: ", bracketState);

    const roundPlaceholderName = rounds?.find((r) => r.round === round);
    const pendingDates = roundPlaceholderName?.pendingDates;
    //console.log("Round Placeholder Name: ", pendingDates);

    // Top Team ID from Playoffs Info in Redis (from Games)
    const [topTeamId, setTopTeamId] = useState(null);
    // Bottom Team ID from Playoffs Info in Redis (from Games)
    const [bottomTeamId, setBottomTeamId] = useState(null);
    // Team ID for Users Choice for the Top of the Bracket
    const [userTopChoice, setUserTopChoice] = useState(null);
    // Team ID for Users Choice for the Bottom of the Bracket
    const [userBottomChoice, setUserBottomChoice] = useState(null);

    //console.log("Top Team ID", topTeamId, "Bottom Team ID", bottomTeamId, "User Top Choice: ", userTopChoice, "User Bottom Choice: ", userBottomChoice);

    // Get the Game ID for this game
    const thisGamesId = games?.find((g) => g.game === game && g.round === round)?.GlobalGameID;
    // Get the Game Info for this game
    const gameInfo = schedule?.find((s) => s.GlobalGameID === thisGamesId);
        //console.log("Game Info: ", gameInfo, thisGamesId);

    // Function to gather live bracket info
    const gatherLiveBracketInfo = (gameInfo) => {
        const isTopTeamHome = gameInfo?.GlobalHomeTeamID === topTeamId;
    
        // Check for scores using both HomeTeamScore/HomeScore and AwayTeamScore/AwayScore
        const homeScore = gameInfo?.HomeTeamScore ?? gameInfo?.HomeScore; // Use HomeTeamScore first, fallback to HomeScore
        const awayScore = gameInfo?.AwayTeamScore ?? gameInfo?.AwayScore; // Use AwayTeamScore first, fallback to AwayScore
    
        const topScore = isTopTeamHome ? homeScore : awayScore;
        const bottomScore = isTopTeamHome ? awayScore : homeScore;
    
        const gameStatus = gameInfo?.Status;
        const gameTime = gameInfo?.DateTime;
    
        const formatDate = (isoDate) => {
            const date = DateTime.fromISO(isoDate, { zone: "America/New_York" }); // Parse and set time zone
            return date.toFormat("ccc, LLL d @ h:mm a 'ET'");
        };
    
        const middleDisplay =
            gameStatus === "Final" || gameStatus === "F/OT"
                ? gameStatus
                : gameStatus === "InProgress"
                ? "LIVE"
                : gameTime !== undefined
                ? formatDate(gameTime)
                : pendingDates;
    
        return {
            topScore,
            bottomScore,
            middleDisplay,
        };
    };
    

    const liveBracketInfo = gatherLiveBracketInfo(gameInfo);
    const { topScore, bottomScore, middleDisplay } = liveBracketInfo; 
    //console.log("Top Score: ", topScore, "Bottom Score: ", bottomScore, "Middle Display: ", middleDisplay);  


    // Use Effect to set the teams
    useEffect(() => {
        //console.log("Setting Teams Use Effect");
    
        // Fetch from current round schedule
        const topTeamIdFromSchedule = games?.find((g) => g.game === game && g.round === round)?.TopTeam;
        const bottomTeamIdFromSchedule = games?.find((g) => g.game === game && g.round === round)?.BottomTeam;
    
        let topTeamIdFromBracketState = null;
        let bottomTeamIdFromBracketState = null;
    
        // Logic for different rounds
        switch (round) {
            case 2:
                // Round 2: Use same game number from previous round
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === game && p.round === round - 1
                );
                break;
    
            case 3:
                // Round 3: Use games (2n-1) and (2n) from previous round
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === ((2 * game) - 1) && p.round === round - 1
                );
                bottomTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === (2 * game) && p.round === round - 1
                );
                break;
    
            case 4:
                // Round 4: Similar to round 3 logic
                topTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === game && p.round === round - 1
                );
                bottomTeamIdFromBracketState = bracketState?.find(
                    (p) => p.game === (game * 2) && p.round === round - 1
                );
                break;
        }
    
        // Prioritize schedule team IDs, then bracket state team IDs
        setTopTeamId(
            topTeamIdFromSchedule || null
        );
    
        setBottomTeamId(
            bottomTeamIdFromSchedule || null
        );

        setUserTopChoice(
            topTeamIdFromBracketState || null
        );

        setUserBottomChoice(
            bottomTeamIdFromBracketState || null
        );
    
    }, [games, game, round, bracketState]);


    // Info about the top and bottom teams in the actual bracket    
    const topTeamInfo = getBracketInfoPostLock(topTeamId, teams, seeds, names);
    const bottomTeamInfo = getBracketInfoPostLock(bottomTeamId, teams, seeds, names);
    //console.log("Top Team Info: ", topTeamInfo, "Bottom Team Info: ", bottomTeamInfo);

    // Users result for the top and bottom team
    const topResult = (skipRoundOne && round === 2) ? "Pending" : userTopChoice ? userTopChoice.result : null;
    const bottomResult = (skipRoundOne && round === 2) ? "Pending" : userBottomChoice ? userBottomChoice.result : null;
    //console.log("Top Result: ", topResult, "Bottom Result: ", bottomResult);

    // Info about the top and bottom team from the users bracket
    const topChoiceInfo = getBracketInfoPostLock(userTopChoice?.teamId, teams, seeds);
    const bottomChoiceInfo = getBracketInfoPostLock(userBottomChoice?.teamId, teams, seeds);
    //console.log("Top Choice Info: ", topChoiceInfo, "Bottom Choice Info: ", bottomChoiceInfo);

    


    return (
        <>
            {(round === 1 || (skipRoundOne && round === 2)) ? null : (
                topResult === "Loss" || !topChoiceInfo ? (
                    <BracketLosingTeam
                        teamInfo={topChoiceInfo}
                        noPick={topChoiceInfo === null}
                    />
                ) : (
                    <Box sx={{ width: '230px', height: '40px' }} />
                )
            )}


            <Box className="super-pickem-bracket-game-container">
                {/* Top Team */}
                {topTeamId ? (
                    <BracketTeamRowLive
                        teamInfo={topTeamInfo}
                        score={topScore}
                        winner={topScore > bottomScore}
                        result={topResult}
                        round={round}
                        top={true}
                    />
                ) : topResult !== "Loss" && topChoiceInfo ? (
                    <BracketTeamRowLive
                        teamInfo={topChoiceInfo}
                        result={topResult}
                        round={round}
                        top={true}
                    />
                ) : (
                    <Box 
                        className="super-pickem-bracket-team-row-placeholder" 
                        sx={{ 
                            border: topChoiceInfo ? '' : '3px solid #CC293C', 
                            backgroundColor: topChoiceInfo ? '' : `${hexToRgba('#CC293C', 0.20)}` 
                        }} 
                    />
                )}


                {/* Game Information */}
                <Box>
                    <Typography variant="h6" className="super-pickem-game-info">
                        {middleDisplay}
                    </Typography>
                </Box>

                {/* Bottom Team */}
                {bottomTeamId ? (
                    <BracketTeamRowLive
                        teamInfo={bottomTeamInfo}
                        score={bottomScore}
                        winner={bottomScore > topScore}
                        result={bottomResult}
                        round={round}
                        top={false}
                    />
                ) : bottomResult !== "Loss" && bottomChoiceInfo ? (
                    <BracketTeamRowLive
                        teamInfo={bottomChoiceInfo}
                        result={bottomResult}
                        round={round}
                        top={false}
                    />
                ) : (
                    <Box 
                        className="super-pickem-bracket-team-row-placeholder" 
                        sx={{ 
                            border: bottomChoiceInfo ? '' : '3px solid #CC293C', 
                            backgroundColor: bottomChoiceInfo ? '' : `${hexToRgba('#CC293C', 0.20)}` }} 
                    />
                )}
            </Box>

            {round === 1 || (round === 2 && !balancedBracket) || (round === 2 && skipRoundOne) ? null : (
                bottomResult === "Loss" || !bottomChoiceInfo ? (
                    <BracketLosingTeam
                        teamInfo={bottomChoiceInfo}
                        noPick={bottomChoiceInfo === null}
                    />
                ) : (
                    <Box sx={{ width: '230px', height: '40px' }} />
                )
            )}
        </>
    );
};

export default BracketGameLive;
