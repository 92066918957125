import React from 'react';
import { Typography, Box, Link } from '@mui/material';

const SponsorText = ({ text }) => (
  <Box sx={{ 
    maxWidth: '700px',
    margin: '0 auto',
    width: '100%',
    '& > *': { mb: 1 }
  }}>
    {text?.map((line, index) => (
      <Typography 
        key={index} 
        component={line.link ? 'div' : 'p'}
        sx={{ 
          fontSize: line.size || '16px',
          color: line.color || '#002129',
          fontWeight: line.fontWeight || 500,
          textAlign: line.alignment || 'center',
          fontStyle: line.italic ? 'italic' : 'normal',
          textDecoration: line.underline ? 'underline' : 'none'
        }}
      >
        {line.link ? (
          <Link 
            href={line.link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            {line.text}
          </Link>
        ) : line.text}
      </Typography>
    ))}
  </Box>
);

export default SponsorText;