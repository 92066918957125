import React from "react";
import { CardMedia  } from "@mui/material";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Video = ({ video, title }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                padding: '16px 0px',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#002129',
                    marginBottom: '16px',
                }}
            >
                {title}
            </Typography>

            <Box 
                className="game-home-page-video-container"
                sx={{
                    width: isMobile ? '220px' : '384px',
                    height: isMobile ? '337px' : '588px',
                }}
            >
                <CardMedia
                    component="video"
                    src={video}
                    allow="autoPlay"
                    controls
                    className="game-home-page-video"
                />
            </Box>
        </Box>
    );
}

export default Video;